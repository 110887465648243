import React, { Fragment, useEffect, useState } from "react";
import { getClientName, getImageDetails } from "Utils/GeneralUtils";
import { Header } from "gce-cxinteracts-ui-components-library";
import ApplicationFlag from "Models/ReduxModels/ApplicationFlag";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styles";
import { Button, Divider } from "@material-ui/core";
import RestoLocalBFilterModal, {
  IRestoLocalBlendFilter
} from "Models/ReduxModels/RestoLocalBlendFilters";
import FilteredRestaurants from "Models/ReduxModels/FilteredRestaurants";

interface IProps {
  applicationFlags: any;
  filterDetails: IRestoLocalBlendFilter;
}

const AvailabilityFilter: React.FC<IProps> = props => {
  const { applicationFlags, filterDetails } = props;
  const clientName = getClientName();
  const history = useHistory();
  let classes = useStyles();
  const [clear, setClear] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (filterDetails?.availability !== "") {
        let avail = filterDetails?.availability?.split(",");

        if (avail?.length) {
          setClear(true);
          avail?.forEach(offer => {
            const stringWithoutWhitespace = offer.trimStart();
            const ele: any = document.getElementById(stringWithoutWhitespace);
            if (ele) {
              ele.checked = true;
            }
          });
        } else {
          setClear(false);
        }
      }
    }, 100);
  }, [filterDetails]);

  const redirectToHome = () => {
    new RestoLocalBFilterModal({
      id: "",
      offerType: [],
      cuisine: "",
      availability: ""
    }).$save();
    let list = {};
    new FilteredRestaurants({
      id: "",
      ...list
    }).$save();
    new ApplicationFlag({
      id: "",
      ...applicationFlags,
      isShowFooter: true,
      isRedirectedFromGiftingSuccessPage: false,
      eatLocalCoffeClubID: 0,
      eatLocalCoffeClubOffer: {},
      selectedShopOrResto: {}
    }).$save();
    setTimeout(() => history && history.push("/home"), 500);
  };

  const goBack = () => {
    new ApplicationFlag({
      id: "",
      ...applicationFlags,
      isShowFooter: true,
      isRedirectedFromGiftingSuccessPage: false,
      offerId: 0
    }).$save();
    setTimeout(() => window.history.back(), 500);
  };

  let availability = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];

  const renderSuggestions = (element: any) => {
    return (
      <>
        <div className={classes.availOptions} key={element}>
          <label htmlFor={element}>{element}</label>
          <input
            type="checkBox"
            id={element}
            name="availability"
            value={element}
            onClick={e => {
              const selectedOffer: any = document.querySelectorAll(
                'input[name="availability"]:checked'
              );
              if (selectedOffer?.length) {
                setClear(true);
              } else {
                setClear(false);
              }
            }}
          />
        </div>
        <Divider />
      </>
    );
    // });
  };

  const handleClick = e => {
    e.preventDefault();
    let selectedDay: any = document.querySelectorAll(
      'input[name="availability"]'
    );
    if (selectedDay) {
      const checkedValues: any = [];
      selectedDay.forEach(checkbox => {
        if (checkbox.checked) {
          checkedValues.push(checkbox.value);
        }
      });
      new RestoLocalBFilterModal({
        id: "",
        ...filterDetails,
        offerType: filterDetails?.offerType || [],
        cuisine: filterDetails?.cuisine || "",
        availability: checkedValues.join(",")
      }).$save();
    }
    history.push("/custom-filters");
  };

  const handleClear = e => {
    e.preventDefault();
    const selectedOffer: any = document.querySelectorAll(
      'input[name="availability"]'
    );

    if (selectedOffer) {
      selectedOffer.forEach(checkbox => {
        checkbox.checked = false;
      });
    }
    setClear(false);
  };

  return (
    <Fragment>
      <Header
        headerText=""
        isHeaderInverted={true}
        svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
        clientLogoPrimary={getImageDetails(
          ImagesMapping[clientName].clientLogoPrimary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        clientLogoSecondary={getImageDetails(
          ImagesMapping[clientName].clientLogoSecondary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        onSvgSelection={goBack}
        onLogoSelection={redirectToHome}
        isScrollHeader={false}
      />

      <form>
        <div className={classes.filterContainer}>
          <div className={classes.filterText}>
            <p className={classes.filterName}>Availability</p>
            {
              <span className={classes.clearText} onClick={handleClear}>
                {clear ? "Clear" : ""}
              </span>
            }
          </div>
          <Divider />

          {availability?.map(element => {
            return renderSuggestions(element);
          })}
          <div className={classes.buttonContainer}>
            <input
              className={classes.formAapplyButton}
              type="submit"
              value="Apply"
              onClick={handleClick}
            />
          </div>
        </div>
      </form>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  let applicationFlags = ApplicationFlag.getInsatnce("", state).props;
  let filterDetails = RestoLocalBFilterModal.getInsatnce("", state)?.props;

  return {
    applicationFlags,
    filterDetails
  };
};

export default connect(mapStateToProps)(AvailabilityFilter);
