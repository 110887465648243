import EventCapturing from "../Models/ReduxModels/EventcapturingModel";
import { omit } from "lodash";
import HttpClient from "Utils/Http";
import { ClientModel } from "Models/ReduxModels/ClientDetails";
import {
  convertLocalDateToISO,
  getCurrentTime,
  getSettingsFromStore
} from "Utils/GeneralUtils";

interface IEventParams {
  eventType: string;
  [key: string]: string | number | any;
}

export const EVENT_ACTION = {
  TOGGLE_ON: "toggle-on",
  CLEAR_ALL: "clear-all"
};
export const EVENT_TYPE = {
  NAVIGATE: "navigate",
  API_CALL: "api-call",
  FETCH_USER_INFO: "fetch-user-info",

  SET_OFFER_FILTER: "set_offer-filter",

  ADD_INTEREST: "add-interest",
  REMOVE_INTEREST: "remove-interest",
  RESET_INTEREST: "reset-interests",

  PROFILE_SAVED: "save-profile",
  NEXT_PAGE_ONBOARDING: "next",
  SET_DOB: "set-dob",

  ADD_FAVOURITE: "save-offer",
  REMOVE_FAVOURITE: "remove-saved-offer",

  CLAIM_OFFER: "claim-offer",
  CONFIRM_CLAIM_OFFER: "confirm-claim-offer",
  SHOW_CLAIM_OFFER_DIALOG: "show-claim-offer-dialog",
  CANCLE_CLAIM_OFFER: "cancel-claim-offer",

  GIFT_AN_OFFER: "gift-an-offer",
  VIEWED_ARTICLE: "viewed article",
  FAQ_VISITED: "faq-clicked",
  PRIZE_DRAW_ENTERED: "prize-draw-entered",
  PRIZE_DRAW_WON: "prize-draw-won",

  REJECTED: "rejected",
  API_ERROR: "fetch-all-api-error",

  VIEW_OFFER: "offers-displayed",
  EATLOCAL_DISPLAYED: "eat-local-offer-displayed",
  REDIRECTED_FROM_ERROR_PAGE: "redirected-from-error-page",

  ONBOARDING_START: "onboarding-start",
  ONBOARDING_END: "onboarding-end",

  ONBOARDING_PROFILE_PERMISSION_LANDED: "onboarding-profile-permission-started",
  ONBOARDING_PROFILE_PERMISSION_EXITED: "onboarding-profile-permission-ended",
  ONBOARDING_LOCATION_PERMISSION_LANDED:
    "onboarding-location-permission-started",
  ONBOARDING_LOCATION_PERMISSION_EXITED: "onboarding-location-permission-ended",
  ONBOARDING_TRAFFIC_PERMISSION_LANDED: "onboarding-traffic-permission-started",
  ONBOARDING_TRAFFIC_PERMISSION_EXITED: "onboarding-traffic-permission-ended",
  ONBOARDING_SMS_PERMISSION_LANDED: "onboarding-sms-permission-started",
  ONBOARDING_SMS_PERMISSION_EXITED: "onboarding-sms-permission-ended",
  ONBOARDING_SUMMARY_LANDED: "onboarding-summary-started",
  ONBOARDING_SUMMARY_EXITED: "onboarding-summary-ended",

  // Eat Local
  SHOW_EAT_LOCAL_ACTIVATION_DIALOG: "show-eat-local-activation-dialog",
  ACTIVATE_EAT_LOCAL: "activate-eat-local",
  TOGGLE_EAT_LOCAL_FAVOURITE_SUCCESS: "toggle-eat-local-favourite-success",
  CANCEL_EAT_LOCAL_ACTIVATION: "cancel-eat-local-activation",
  CONFIRM_ACTIVATE_EAT_LOCAL_SUCCESS: "confirm-activate-eat-local-success",
  CONFIRM_ECODE_EAT_LOCAL_SUCCESS: "claimapi-for-ecode-restaurants-success",
  EAT_LOCAL_SEARCH_AFTER_ACTIVATION: "eat-local-search-after-activation",
  CLOSE_EAT_LOCAL_MEMBERSHIP_DETAILS_DIALOG:
    "close-eat-local-membership-details-dialog",
  TOGGLE_EAT_LOCAL_FAVOURITE: "toggle-eat-local-favourite",
  CONFIRM_ACTIVATE_EAT_LOCAL_ERROR: "confirm-activate-eat-local-error",
  CONFIRM_ECODE_EAT_LOCAL_ERROR: "claimapi-for-ecode-restaurants-error",
  EAT_LOCAL_SEARCH_BEFORE_ACTIVATION: "eat-local-search-before-activation",
  CONFIRM_ACTIVATE_EAT_LOCAL: "confirm-activate-eat-local",
  SHOW_EAT_LOCAL_MEMBERSHIP_DETAILS_DIALOG:
    "show-eat-local-membership-details-dialog",
  EAT_LOCAL_SEARCH_JOURNEY_START: "eat-local-restaurant-journey-start",
  EAT_LOCAL_SEARCH_RESTAURANT: "search-restaurants",
  EAT_LOCAL_RESTAURANT_LIST: "eat-local-restaurant-list",
  SHOW_EAT_LOCAL_MEMBERSHIP_SCREEN:
    "eat-local-membership-activation-page-displayed",
  SHOW_EAT_LOCAL_CARD: "eat-local-usecard-displayed",
  EAT_LOCAL_USE_CARD: "eat-local-restaurant-usecard",
  EAT_LOCAL_MEMBERSHIP_CLICK: "eat-local-membership-click",
  EAT_LOCAL_RESTAURANT_CLICK: "eat-local-restaurant-details-displayed",
  EAT_LOCAL_QRCODE_DISPLAY: "eat-local-qrcode-displayed",
  USE_CARD_CLICK: "use-card-click",

  // Coffee Club
  COFFEE_CLUB_DISPLAYED: "coffee-club-offer-displayed",
  SHOW_COFFEE_CLUB_ACTIVATION_DIALOG: "show-coffee-club-activation-dialog",
  ACTIVATE_COFFEE_CLUB: "activate-coffee-club",
  TOGGLE_COFFEE_CLUB_FAVOURITE_SUCCESS: "toggle-coffee-club-favourite-success",
  CANCEL_COFFEE_CLUB_ACTIVATION: "cancel-coffee-club-activation",
  CONFIRM_ACTIVATE_COFFEE_CLUB_SUCCESS: "confirm-activate-coffee-club-success",
  COFFEE_CLUB_SEARCH_AFTER_ACTIVATION: "coffee-club-search-after-activation",
  CLOSE_COFFEE_CLUB_MEMBERSHIP_DETAILS_DIALOG:
    "close-coffee-club-membership-details-dialog",
  TOGGLE_COFFEE_CLUB_FAVOURITE: "toggle-coffee-club-favourite",
  CONFIRM_ACTIVATE_COFFEE_CLUB_ERROR: "confirm-activate-coffee-club-error",
  COFFEE_CLUB_SEARCH_BEFORE_ACTIVATION: "coffee-club-search-before-activation",
  CONFIRM_ACTIVATE_COFFEE_CLUB: "confirm-activate-coffee-club",
  SHOW_COFFEE_CLUB_MEMBERSHIP_DETAILS_DIALOG:
    "show-coffee-club-membership-details-dialog",
  COFFE_CLUB_SEARCH_JOURNEY_START: "coffee-club-coffeeshops-journey-start",
  SEARCH_SCREEN_DISPLAYED: "search-page-displayed",
  LOCAL_BLEND_SEARCH_COFFEE_SHOPS: "search-shops",
  LOCAL_BLEND_RESTAURANT_LIST: "coffee-club-shops-list",
  SHOW_COFFEE_CLUB_MEMBERSHIP_SCREEN:
    "coffee-club-membership_activation-page-displayed",
  SHOW_LOCAL_BLEND_COFFEE_CLUB_CARD: "coffee-club-use-card-displayed",
  COFFEE_CLUB_USE_CARD: "coffee-club-shops-usecard",
  COFFEE_CLUB_MEMBERSHIP_CLICK: "coffee-club-membership-click",
  COFFEE_CLUB_SHOP_CLICK: "coffee-club-shop-details-displayed",
  COFFEE_CLUB_QRCODE_DISPLAY: "coffee-club-qrcode-displayed",
  COFFEE_CLUB_MEMBERSHIP_ACTIVATION_USE_CARD: "coffee-club-membership-use-card",

  // Lite Events
  SHOW_VMR_LITE_BANNER_OFFER: "show-vmr-lite-banner-offer",
  CONFIRM_VMR_LITE_TOP_UP_BUTTON: "confirm-vmr-lite-top-up-button",
  OFFER_SCANNED_QRCODE: "offer-scanned-through-qrcode",
  NAVIGATE_FILTERS: "navigate-to-restaurant-filters",
  APPLIED_FILTERS: "applied-filters"
};

export const logEvents = (eventLogged: IEventParams) => {
  const savedEvents = EventCapturing.getInsatnce("")?.props;
  const clientDetails = ClientModel.getInsatnce("")?.props;

  const eventMetaData = {
    eventType: eventLogged.eventType,
    timestamp: convertLocalDateToISO(getCurrentTime()),
    session_id: clientDetails.sessionId || "",
    offerId: Number(eventLogged?.offerId) || null,
    eventPayload: omit(eventLogged, ["eventType", "offerId"])
  };
  let eventsArray = savedEvents?.eventData || [];
  if (eventsArray.length === 10) {
    pushEventsToServer(eventsArray);
    eventsArray = [];
  }

  eventsArray.push({ ...eventMetaData });
  new EventCapturing({
    id: "",
    eventData: eventsArray
  }).$save();
};

export const pushAllEventsToServer = async () => {
  const savedEvents = EventCapturing.getInsatnce("")?.props;

  pushEventsToServer(savedEvents?.eventData || []);
  new EventCapturing({
    id: "",
    eventData: []
  }).$save();
};

export const pushEventsToServer = async eventsArray => {
  const httpClient = new HttpClient();
  const url = getSettingsFromStore("eventServiceEndPoint");
  const resp = httpClient.post(url, eventsArray);
};
