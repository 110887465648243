export const FetchAll = `query fetchCustomerDetails ($tenantId:String!) {
    fetchAll(offersRequest:{
        pagingInfo: {
            pageSize: 100,
            pageNumber: 0
        },
        tenantId: $tenantId
        appName: "MVP_UI"
    } ){
        appVersion
        externalMemberRegistrationId
        attributes
        offers {          
            id
            isStoreGiftingOffer
            isConvergedOffer
            offerType
            isQRActive
            title
            giveToLiteUser
            offerPrizeDetails {
                prizeName
                voucherCodeExpiryModelType
                voucherCodePresentation
                voucherCodeExpiryModelValue
            }
            additionalDetails {
                key
                value
            }
            offerRating{
                id
                feedback
                offerId
                customerId
                offerRating
                createdOn
            }
            carouselCardTextColor
            claimConditionsFailed {
                dimension,
                subDimension,
                values,
            }
            claimLimitsReached {
                notEnoughCodes
                total
                totalPerPeriod
                user
                userPerPeriod
            }
            ctaDetails {
                key
                value
            }
            comingSoonDetails{
                shortTitle,
                title
                images{
                  classifier
                  imageUrl
                  altText
                }
              }
            description
            endDateTime
            images {
                imageUrl
                classifier
                altText
            }
            keyTerms
            entryMechanics
            merchant {
                id
                name
                imageUrl
                altText
            }
            preClaimAdvice
            redemptionDetails {
                guidelines
                method
                type
                url
                prizeName
                captureEmail
            }
            startDateTime
            teaser
            termsAndConditions
            termsAndConditionsUrl
            titleShort
            shareable
            showPreClaimPopup
            voucherCodeExpiryModelType
            weight
        }
        appConfig {
            appName
            appSectionList {
                configurationDataList {
                    id
                    lastUpdated
                    name
                    type
                    value
                }
                description
                id
                lastUpdated
                name
            }
            lastUpdated
            tenantId
          interests {
            id
            name
          }
        }
        carousel {
            label
            offerId
            collectionCard {
                id
                images {
                  imageUrl
                  classifier
                  altText
                }
                offerIds
                showOverlayText
                subTitle
                title
                textColor
            }
        }
        announcement{
            id,
            title
            description
            ctaRedirect
            entityId 
            ctaColor
            ctaText
            backgroundImage
            iconImage
            bannerImage
          }
        outlets {
            businessHours
            description
            id
            active
            latitude
            longitude
            merchantId
            name
            offerIds
            phoneNumber
        }
        claimedOffers {
            claims {
                id
                offerId
                attributes
                byAgent
                claimDateTime
                expiryDateTime
                memberId
                offerType
                redemptionCode
                source
                prizeName
            }
            pagingInfo {
                pageNumber
                pageSize
                totalResults
            }
        }
        favourites {
            favourites {
                favouriteOfferId {
                    offerId
                }
                createdOn
            }
            pagingInfo {
                pageNumber
                pageSize
                totalResults
            }
        }
        displayCategories {
            id
            name
            offerIds
            displayPosition
            imageUrl
            pagingInfo {
                pageNumber
                pageSize
                totalResults
            }
        }
        firstName
        id
        lastName
        externalMemberId
        profile {
            dob
            firstUse
            interests {
              id
              name
            }
            permissions {
                call
                email
                location
                profiling
                sms
                traffic
            }
        }
        totalClaimedOffers
        appVersion
    }
}`;

export const getFavouriteOffers = `query getFavouriteOffers($pageNo:Int!,$pageSize:Int!) {
    favourite(pageNo:$pageNo, pageSize:$pageSize) {
      totalPages
      totalElements
      content {
        favouriteOfferId {
          memberId
          offerId
        }
        createdOn
      }
    }
  }
  `;

export const getClaimedOffers = `query getClaimedOffers($pageNumber:Int!,$pageSize:Int!){
        claimedOffers(pageNo:$pageNumber, pageSize:$pageSize) {
            pagingInfo {
                pageSize
                pageNumber
                totalResults
            }
            claimedOffers {
                id
                claimDateTime
                expiryDateTime
                offerId
                attributes
            }
            offers {
                id
                claimConditionsFailed {
                    dimension,
                    subDimension,
                    values,
                }
                claimLimitsReached {
                    notEnoughCodes
                    total
                    totalPerPeriod
                    user
                    userPerPeriod
                }
            }
        }
    }`;

export const getOfferByDisplayCategoryId = `query getOfferByDisplayCategoryId($displayCategoryId:Long,$pageNo:Int!,$pageSize:Int!){
    offerByDisplayCategoryId(displayCategoryId:$displayCategoryId, pageNo:$pageNo, pageSize:$pageSize) {
                displayCategory {
                    id
                    name
                    imageUrl
                    pagingInfo {
                        totalResults
                        pageNumber
                        pageSize
                    }
                    offerIds
                }
                offers {
                    additionalDetails {
                        key
                        value
                    }
                    carouselCardTextColor
                    claimConditionsFailed {
                        dimension,
                        subDimension,
                        values,
                    }
                    claimLimitsReached {
                        notEnoughCodes
                        total
                        totalPerPeriod
                        user
                        userPerPeriod
                    }
                    ctaDetails {
                        key
                        value
                    }
                    description
                    endDateTime
                    id
                    images {
                        imageUrl
                        classifier
                    }
                    keyTerms
                    entryMechanics
                    merchant {
                        id
                        name
                        imageUrl
                    }
                    offerType
                    preClaimAdvice
                    redemptionDetails {
                        guidelines
                        method
                        type
                        url
                    }
                    startDateTime
                    teaser
                    termsAndConditions
                    termsAndConditionsUrl
                    title
                    titleShort
                }
        }
    }`;
