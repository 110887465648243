import React, { useState, useEffect, Fragment } from "react";
import { Interest, Header } from "gce-cxinteracts-ui-components-library";
import Button from "@material-ui/core/Button";
import { useStyles } from "./styles";
import { ConstantMapping } from "../../Configurations/ConstantsMapping";
import Interests, { ProfileInterest } from "../../Models/ReduxModels/Interests";
import {
  ClientModel,
  ClientSessionDetails
} from "../../Models/ReduxModels/ClientDetails";
import { connect } from "react-redux";
import { History } from "history";
import UserProfile, {
  UserProfileDetails
} from "../../Models/ReduxModels/UserProfileModel";
import { getImageDetails } from "../../Utils/GeneralUtils";
import { updateProfileApiCall } from "../../Services/ProfileService";
import {
  sanitizeInterestData,
  getInterestsData
} from "../../Utils/InterestUtil";
import { logEvents, EVENT_TYPE } from "../../Services/EventService";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { getClientName, redirectToNextRoute } from "Utils/GeneralUtils";

interface IProps {
  history: History;
  match: any;
  clientData: ClientSessionDetails;
  userProfileDetails: UserProfileDetails;
  interestsData: ProfileInterest;
  userProfileDetailsLocal: UserProfileDetails;
}

const EditInterest: React.FC<IProps> = (props: IProps) => {
  let id = props.match.params.id;
  const {
    history,
    userProfileDetails,
    interestsData,
    userProfileDetailsLocal
  } = props;
  let classes = useStyles();
  const [reset, resetValues] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [disable, disableButton] = useState(false);
  const [interests, setInterests] = useState([]);
  const clientName = getClientName();
  let userSelectedInterests =
    userProfileDetailsLocal?.interests || userProfileDetails?.interests;
  let isUpdateCallIP = false;

  useEffect(() => {
    if (!selectedValues.length) {
      resetValues(false);
    }
    if (selectedValues.length >= 5) {
      disableButton(false);
    } else {
      disableButton(true);
    }
  }, [selectedValues]);

  useEffect(() => {
    if (!interestsData) return;
    let extractedInterests: any = [];
    extractedInterests = Object.keys(interestsData).map(function (key) {
      return interestsData[key];
    });
    extractedInterests.pop();
    let updatedInterests = extractedInterests.map((item: any) => {
      let o = Object.assign({}, item);
      o.isSelected = false;
      return o;
    });
    if (userSelectedInterests && userSelectedInterests.length) {
      let updatedInterestsData: any = [];
      let selectedData: any = [];
      updatedInterestsData = updatedInterests.map((data: any) => {
        let obj = Object.assign({}, data);
        userSelectedInterests.map((item: any) => {
          if (obj.id === item) {
            obj.isSelected = true;
            selectedData.push(obj);
          }
        });
        return obj;
      });
      setInterests(updatedInterestsData);
      setSelectedValues(selectedData);
      setSelectedInterests(selectedData);
    } else {
      setInterests(updatedInterests);
    }
  }, [interestsData]);

  const resetInterest = () => {
    logEvents({ eventType: EVENT_TYPE.RESET_INTEREST });
    resetValues(true);
  };

  const handleSvg = (id: number) => {
    if (id) {
      return ConstantMapping[clientName].SVG.headerSvgStyle;
    }
  };

  const renderInterests = () => {
    return (
      interests &&
      interests.length && (
        <div className={classes.interest}>
          <Interest
            interests={interests}
            selectedInterests={(value: any) => {
              renderSelectedInterests(value);
            }}
            onReset={reset}
            circleRadius={35}
            className={classes.mainContent}
          ></Interest>
        </div>
      )
    );
  };

  const renderDescriptionText = () => {
    return (
      <div className={classes.textWrapper}>
        <h2 className={classes.heading}>
          {id
            ? ConstantMapping[clientName].INTEREST_HEADING
            : ConstantMapping[clientName].ONBOARDING_INTEREST_HEADING}
        </h2>
        <p className={classes.descriptionText}>
          {id
            ? ConstantMapping[clientName].INTEREST_PARAGRAPH_TEXT
            : ConstantMapping[clientName].ONBOARDING_INTEREST_VALIDATION_TEXT}
        </p>
      </div>
    );
  };

  const updateInterest = async () => {
    if (!isUpdateCallIP) {
      isUpdateCallIP = true;

      const { permissions, dob } = userProfileDetails;
      const selectedInterests = sanitizeInterestData(selectedValues);
      const profilePayload = {
        dob,
        firstUse: false,
        permissions,
        interests: getInterestsData(selectedInterests)
      };
      const resp = await updateProfileApiCall(profilePayload);
      if (resp?.data?.updateProfile) {
        const { firstUse, interests, ...rest } = resp?.data?.updateProfile;
        isUpdateCallIP = false;
        new UserProfile({
          id: "",
          isFirstUse: firstUse,
          interests: sanitizeInterestData(interests),
          ...rest
        }).$save();
        history.push("/myProfile");
      } else {
        isUpdateCallIP = false;
        console.log("Something went wrong !!");
      }
    }
    if (selectedValues.length !== selectedInterests.length) {
      const interestAdded = selectedValues.length > selectedInterests.length;
      const eventData = {
        eventType: interestAdded
          ? EVENT_TYPE.ADD_INTEREST
          : EVENT_TYPE.REMOVE_INTEREST
      };
      logEvents(eventData);
    }
    logEvents({ eventType: EVENT_TYPE.PROFILE_SAVED });
  };

  const renderSelectedInterests = (value: any) => {
    setSelectedValues(value);
  };

  const renderInterestPageButtons = () => {
    return (
      <div className={classes.buttonWrapper}>
        <Button className={classes.resetButton} onClick={resetInterest}>
          {ConstantMapping[clientName].RESET_BUTTON_TEXT}
        </Button>
        <Button
          variant="contained"
          disabled={disable}
          className={classes.saveButton}
          onClick={updateInterest}
        >
          {ConstantMapping[clientName].SAVE_BUTTON_TEXT}
        </Button>
      </div>
    );
  };

  const redirectToHome = () => {
    history.push("/home");
  };
  return (
    <Fragment>
      <div className={classes.mainContainer}>
        <Header
          headerText={id ? "Back" : ""}
          svgProps={handleSvg(id)}
          isHeaderInverted={false}
          clientLogoPrimary={getImageDetails(
            ImagesMapping[clientName].clientLogoPrimary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          clientLogoSecondary={getImageDetails(
            ImagesMapping[clientName].clientLogoSecondary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          onSvgSelection={() => window.history.back()}
          isScrollHeader={id ? true : false}
          onLogoSelection={id ? redirectToHome : () => {}}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.wrapper}>
            <div className={classes.content}>
              {renderInterests()}
              {renderDescriptionText()}
              {renderInterestPageButtons()}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  let clientData = ClientModel.getInsatnce("", state)?.props;
  let interestsData = Interests.getInsatnce("", state)?.props;
  let userProfileDetails = UserProfile.getInsatnce("", state)?.props;
  let userProfileDetailsLocal = UserProfile.getInsatnce("local", state)?.props;
  return {
    clientData,
    userProfileDetails,
    interestsData,
    userProfileDetailsLocal
  };
};

export default connect(mapStateToProps)(EditInterest);
