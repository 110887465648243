//@ts-nocheck

import React, {
  useEffect,
  useState,
  Fragment,
  useCallback,
  useLayoutEffect,
  useRef
} from "react";
import {
  Header,
  Filter,
  AnimationHelper
} from "gce-cxinteracts-ui-components-library";
import { debounce, throttle } from "lodash";
import { connect } from "react-redux";
import { makeStyles, createStyles } from "@material-ui/core";
import FlipMove from "react-flip-move";
import { IFilter } from "gce-cxinteracts-ui-components-library/dist/Filter/Constants";
import DisplayCategories, {
  IDisplayCategory,
  DisplayCategoryIds
} from "Models/ReduxModels/DisplayCategories";
import Offers, { IOffers } from "Models/ReduxModels/OffersModel";
import Carousel, { CarouselItem } from "Models/ReduxModels/Carousel";
import Announcement, { IAnnouncement } from "Models/ReduxModels/Announcement";
import Merchants, { IMerchant } from "Models/ReduxModels/Merchants";
import FavouriteOffers, {
  IFavouriteOffers
} from "Models/ReduxModels/FavouriteOffersModel";
import CarouselComponent from "./Carousel";
import {
  addRemoveFavouriteOffers,
  getLatestClaimDetailsByClaimId,
  isObjectEmpty,
  retireveBannerDetails
} from "Utils/offerUtils";
import UserProfile from "Models/ReduxModels/UserProfileModel";
import { updateProfileApiCall } from "Services/ProfileService";
import { sanitizeInterestData, getInterestsData } from "Utils/InterestUtil";
import {
  logEvents,
  EVENT_TYPE,
  EVENT_ACTION,
  pushAllEventsToServer
} from "Services/EventService";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import {
  getImageDetails,
  findEditedPermissions,
  getClientName,
  iOSDevice,
  getCurrentTime,
  loadEvents,
  previousPages,
  updateSaveUnsave_OfferCount,
  manualParameterPass,
  checkChristmasMonth
} from "Utils/GeneralUtils";
import ClaimedOffers, {
  IClaimedOffers
} from "Models/ReduxModels/ClaimedOffersModel";
import GridOffer from "Components/GridOffer/GridOffer";
import OfferIdsSortedByWeight from "Models/ReduxModels/OfferIdsSortedByWeight";
import SortedFavouriteOfferIds, {
  OfferIds
} from "Models/ReduxModels/SortedFavouriteOfferIds";
import { differenceBy, isEmpty, pick } from "lodash";
import { spring } from "react-motion";
import UserDetails, { IUserDetails } from "Models/ReduxModels/UserDetails";
import GenericDialog from "Components/Dialog/GenericDialog";
import Filters from "Models/ReduxModels/FilterModel";
import { findIndex } from "lodash";
import { Settings, ISettings } from "Models/ReduxModels/Settings";
import { ImagesMapping } from "Configurations/ImagesMapping";
import ApplicationFlag, {
  IApplication
} from "Models/ReduxModels/ApplicationFlag";
import { useHistory } from "react-router-dom";
import { ConvergedOffer } from "./ConvergedOffer";
import CMSImages, {
  IVisitoWinCongigurations
} from "Models/ReduxModels/CMSImages";
import announcementBanner1 from "../../../Configurations/Clients/vodafone/Assets/Images/announcementBanner1.png";
import announcementWhiteBg from "../../../Configurations/Clients/vodafone/Assets/Images/announcementWhiteBg.png";
import { OfferType } from "Models/Interfaces/OfferType";
import Scrolls, { IScroll } from "Models/ReduxModels/ScrollModel";

const useStyles = makeStyles(theme =>
  createStyles({
    filter_container: {
      minHeight: "100vh"
    },
    filter: {
      paddingTop: "0px",
      position: "sticky",
      top: "-1px",
      zIndex: 10
    },
    offerFeedWrapper: {
      width: "calc((100% - 5vw) - 9px)",
      overflow: "hidden"
    },
    offerContainer: {
      display: "grid",
      position: "relative",
      gridTemplateColumns: "1fr 1fr",
      gridAutoFlow: "dense"
    },

    applySticky: {
      position: "sticky"
    },
    noOffer: {
      display: "flex",
      flexDirection: "column",
      margin: "10vw 14vw 50vw 14vw"
    },
    emptyBox: {
      height: "25vw"
    },
    noRewardHeading: {
      textAlign: "center",
      fontSize: theme.typography.subtitle1.fontSize,
      fontFamily: theme.typography.fontFamily,
      margin: theme.spacing(0.5, 0, 0, 0),
      letterSpacing: "0.4px"
    },
    noRewardsText: {
      textAlign: "center",
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.subtitle1.fontSize,
      marginTop: theme.spacing(4),
      lineHeight: "17px"
    },
    buttonOkStyleText: {
      fontFamily: "VodafoneRg",
      fontWeight: "bold",
      fontSize: theme.typography.h5.fontSize
    }
  })
);

interface IProps {
  carousel: CarouselItem[];
  displayCategories: IDisplayCategory;
  offers: IOffers;
  merchants: IMerchant;
  displayCategoryIds: number[];
  favorites: IFavouriteOffers;
  userProfileDetails: any;
  claimedOffers: IClaimedOffers;
  offerIdsSortedByWeight: number[];
  favouriteOfferIds: OfferIds;
  filters: IFilter;
  userDetails: IUserDetails;
  settings: ISettings;
  applicationFlags: IApplication;
  cms_v2w_images: IVisitoWinCongigurations;
  announcementModuleData: IAnnouncement;
  scrolls: IScroll;
}

const Home: React.FC<IProps> = props => {
  const classes = useStyles();

  const {
    carousel,
    displayCategories,
    offers,
    merchants,
    displayCategoryIds,
    favorites,
    userProfileDetails,
    claimedOffers,
    offerIdsSortedByWeight,
    favouriteOfferIds,
    filters,
    userDetails,
    applicationFlags,
    cms_v2w_images,
    announcementModuleData,
    scrolls
  } = props;

  const [appliedFilters, setAppliedFilters] = React.useState<IFilter[]>([]);
  const [gridOffers, setGridOffers] = React.useState<number[]>([]);
  const [totalGridOffers, setTotalGridOffers] = React.useState<number[]>([]);
  const [openAppVersionDialog, setOpenAppVersionDialog] =
    React.useState<boolean>(false);
  let filtersCategories: any[] = [];
  //Sorted display categories in the order they are coming from API and not by id
  displayCategoryIds &&
    displayCategoryIds.length &&
    displayCategoryIds.forEach(dcId => {
      filters[dcId] && filtersCategories.push(filters[dcId]);
    });
  const [showEnterTransition, setEnterTransition] = useState(false);
  const [filtersData, setFiltersData] = useState(filtersCategories);
  const [showSavedOfferAnimation, setSavedOfferAnimation] = useState(-1);
  const clientName = getClientName();
  const defaultOfferContainerMargin: any = "7px calc(5vw - 9px) 17vw 5vw";
  const [offerContainerMargin, setOfferContainerMargin] = useState(
    defaultOfferContainerMargin
  );
  const [openVMRLiteDialog, setOpenVMRLiteDialog] =
    React.useState<boolean>(false);
  const [openpaygPlusDialog, setOpenpaygPlusDialog] =
    React.useState<boolean>(false);
  const [gridOffersNotAvailable, setNoGridOffers] =
    React.useState<boolean>(false);
  const [openVisitToWinOverlayPopup, setOpenVisitToWinOverlayPopup] =
    React.useState<boolean>(false);
  const rootElement: any = document.getElementById("root");
  const root = document.getElementById("root");
  let isFavoriteCallIP = false;
  const history = useHistory();
  let isChristmas = checkChristmasMonth(
    ConstantMapping[clientName].XMAS_IW_START_DATE,
    ConstantMapping[clientName].XMAS_IW_END_DATE
  );
  const [convergedOffer, setConvergedOffer] = React.useState<number[]>([]);
  const [openAnnoucementDialog, setAnnouncementDialog] =
    React.useState<boolean>(false);
  let announcementInfo = {
    background_image: announcementWhiteBg,
    top_icon_image: "",
    banner_image: announcementBanner1,
    cta_color: "#e60000",
    title: "Offer Announcement",
    description:
      "Versions of the Lorem ipsum text have been used in typesetting at least since the 1960s, when it was popularized by advertisements for Letraset transfer sheets.",
    cta_redirect: "home",
    cta_text: "Take me there!",
    offerId: null
  };
  const [announcementModuleInfo, setAnnouncementModuleInfo] =
    React.useState(announcementInfo);

  const resetScroll = () => {
    if (root) {
      root.scrollTop = 0;
    }
  };
  const cardTypes = {
    eatLocal: "Eat Local",
    payGTrial: "PayGTrial",
    coffeeClub: "Coffee Club"
  };

  useEffect(() => {
    if (rootElement) {
      if (iOSDevice() && rootElement.offsetHeight > 700) {
        setOfferContainerMargin("7px calc(5vw - 9px) 21vw 5vw");
      } else {
        setOfferContainerMargin(defaultOfferContainerMargin);
      }
    }
    // Clear the history of onboarding screen
    window.history.replaceState("onLoadParams", "");
  }, []);

  useEffect(() => {
    const isMock = false;
    //resetScroll();

    if (userProfileDetails?.isFirstUse || isMock) {
      updateProfile(isMock);
      logEvents({ eventType: EVENT_TYPE.ONBOARDING_END });
      const deeplink = "" + applicationFlags?.showOfferDetailsDeepLink;
      if (applicationFlags?.showOfferDetailsDeepLink) {
        const offerId = deeplink.split("/").pop();
        logEvents({
          eventType: EVENT_TYPE.OFFER_SCANNED_QRCODE,
          offerId: offerId
        });
        history.push(deeplink);
      }
      pushAllEventsToServer();
    }

    if (
      userDetails &&
      userDetails.appVersion &&
      ConstantMapping[clientName].CHECK_APP_VERSION &&
      (userDetails.appVersion.major <
        ConstantMapping[clientName].MINIMUM_APP_VERSION.major ||
        (userDetails.appVersion.major ==
          ConstantMapping[clientName].MINIMUM_APP_VERSION.major &&
          userDetails.appVersion.minor <
            ConstantMapping[clientName].MINIMUM_APP_VERSION.minor)) &&
      !localStorage.getItem(
        ConstantMapping[clientName].APP_UPDATE_DIALOG_LOCAL_STORAGE_KEY
      )
    ) {
      setOpenAppVersionDialog(true);
    }

    // Add the key in local storage if -
    // In first fetch call isVMRLite is true and in second fetch call if isVMRLite is false in this case only we need to show only vmrLite popup
    // In first fetch call only isVMRLite is false, then we are not going to show the popup here as this user already has access to all rewards
    if (
      userDetails &&
      userDetails?.appAccessLevel === "PAYG_LITE" &&
      !localStorage.getItem("CheckVMRLitePopup")
    ) {
      localStorage.setItem("CheckVMRLitePopup", getCurrentTime().toString());
    }

    // VMRLite Dialog popupModal
    if (
      userDetails &&
      !(userDetails?.appAccessLevel === "PAYG_LITE") &&
      !localStorage.getItem("VMRLite") &&
      localStorage.getItem("CheckVMRLitePopup")
    ) {
      setOpenVMRLiteDialog(true);
    }

    //set paygplus check in local storage when user is paygplus and visits application for first time
    if (
      userDetails &&
      userDetails?.appAccessLevel === "PAYG_PLUS_LITE" &&
      !localStorage.getItem("CheckPayGplusPopup")
    ) {
      localStorage.setItem("CheckPayGplusPopup", getCurrentTime().toString());
    }

    //PAYGPLUS Dialog PopupModal
    if (
      userDetails &&
      !(userDetails?.appAccessLevel === "PAYG_PLUS_LITE") &&
      !localStorage.getItem("PAYGPlus") &&
      localStorage.getItem("CheckPayGplusPopup")
    ) {
      setOpenpaygPlusDialog(true);
    }

    // VisitToWin Dialog popupModal
    if (userDetails && userDetails?.halfVisitToWinPopup) {
      setOpenVisitToWinOverlayPopup(true);
    }

    if (!isObjectEmpty(announcementModuleData)) {
      let updatedData = {
        ...announcementModuleInfo,
        background_image:
          announcementModuleData.backgroundImage !== ""
            ? announcementModuleData.backgroundImage
            : announcementInfo.background_image,
        top_icon_image:
          announcementModuleData.iconImage !== ""
            ? announcementModuleData.iconImage
            : "",
        banner_image:
          announcementModuleData.bannerImage !== ""
            ? announcementModuleData.bannerImage
            : announcementInfo.banner_image,
        cta_color: announcementModuleData.ctaColor,
        title: announcementModuleData.title,
        description: announcementModuleData.description,
        cta_redirect: announcementModuleData.ctaRedirect,
        offerId: announcementModuleData.offerId,
        cta_text: announcementModuleData.ctaText
      };

      setAnnouncementModuleInfo(updatedData);

      if (applicationFlags.isShowAnnouncementPopup) {
        setAnnouncementDialog(true);
      } else {
        setAnnouncementDialog(false);
      }
    }

    setEnterTransition(true);
    //update popup flag after showing it once
    let showAnnouncementModel = false;
    if (applicationFlags.isShowAnnouncementPopup) {
      showAnnouncementModel = false;
    }
    new ApplicationFlag({
      id: "",
      ...applicationFlags,
      eatLocalCoffeClubID: 0,
      eatLocalCoffeClubOffer: {},
      selectedShopOrResto: {},
      isShowAnnouncementPopup: showAnnouncementModel,
      showOfferDetailsDeepLink: ""
    }).$save();
  }, []);

  useEffect(() => {
    let gridOfferIds: any = {};
    let convergedOfferIds: any = {};
    displayCategoryIds?.forEach((id: number, index: number) => {
      displayCategories[id].offerIds &&
        displayCategories[id].offerIds.forEach((id: any) => {
          if (!offers[id]?.isConvergedOffer) {
            if (!gridOfferIds[id]) {
              gridOfferIds[id] = 1;
            }
          } else {
            if (!convergedOfferIds[id]) {
              convergedOfferIds[id] = 1;
            }
          }
        });
    });
    const gridOfferIdList: number[] = getOfferIdsSortedByWeight(gridOfferIds);
    const convergedOfferIdList: number[] =
      getOfferIdsSortedByWeight(convergedOfferIds);
    setGridOffers(gridOfferIdList);
    setTotalGridOffers(gridOfferIdList);
    setConvergedOffer(convergedOfferIdList);
  }, [displayCategories, displayCategoryIds, offerIdsSortedByWeight]);

  useEffect(() => {
    let gridOfferIds: any = {};
    let selectedFilters: any = [];
    selectedFilters = (filtersData || []).filter((filter: any) => {
      if (filter.isSelected === true) {
        return filter;
      }
    });

    if (selectedFilters && selectedFilters.length) {
      selectedFilters.forEach((filter: IFilter) => {
        displayCategories[+filter.name].offerIds &&
          displayCategories[+filter.name].offerIds.forEach((id: any) => {
            if (!offers[id]?.isConvergedOffer) {
              if (!gridOfferIds[id]) {
                gridOfferIds[id] = 1;
              }
            }
          });
      });
      let gridOfferIdList: number[] = getOfferIdsSortedByWeight(gridOfferIds);
      if (gridOfferIdList.length === 0) {
        setNoGridOffers(true);
      } else {
        setNoGridOffers(false);
      }
      setGridOffers(gridOfferIdList);
    } else {
      setGridOffers(totalGridOffers);
      setNoGridOffers(false);
    }
  }, [appliedFilters, totalGridOffers]);

  const isRestoringScroll = useRef(false);

  useEffect(() => {
    const fixedElementHeight = document.getElementById("main-container");

    const handleScroll = debounce(() => {
      if (fixedElementHeight && !isRestoringScroll.current) {
        const scrollTop = fixedElementHeight.scrollTop;
        new Scrolls({
          id: "",
          scrollCurrentPosition: scrollTop
        }).$save();
      }
    }, 150);

    if (fixedElementHeight) {
      // Temporarily set the flag to true to prevent the scroll event from being handled
      isRestoringScroll.current = true;
      // Function to restore scroll position
      const restoreScrollPosition = () => {
        if (
          scrolls?.scrollCurrentPosition &&
          scrolls.scrollCurrentPosition !== 0
        ) {
          requestAnimationFrame(() => {
            fixedElementHeight.scrollTo(0, scrolls.scrollCurrentPosition);
            // Add the scroll event listener after a delay to ensure the restoration process is complete
            setTimeout(() => {
              isRestoringScroll.current = false;
              fixedElementHeight.addEventListener("scroll", handleScroll);
            }, 100);
          });
        } else {
          // Add the scroll event listener immediately if there's no scroll position to restore
          setTimeout(() => {
            isRestoringScroll.current = false;
            fixedElementHeight.addEventListener("scroll", handleScroll);
          }, 100);
        }
      };

      // Restore the scroll position
      restoreScrollPosition();
      return () => {
        fixedElementHeight.removeEventListener("scroll", handleScroll);
      };
    }
  }, [scrolls]);

  const getOfferIdsSortedByWeight = (gridOfferIds: any) => {
    const gridOfferIdList: number[] = [];
    const totalOffersInGrid = Object.keys(gridOfferIds).length;
    for (let index = 0; index < offerIdsSortedByWeight.length; index++) {
      if (gridOfferIds[offerIdsSortedByWeight[index]] === 1) {
        gridOfferIdList.push(offerIdsSortedByWeight[index]);
      }
      if (totalOffersInGrid === gridOfferIdList.length) {
        break;
      }
    }
    return gridOfferIdList;
  };

  const loadAdobeTealiumEvent = (label?: any) => {
    let e_label: any = "";
    let e_name;
    let e_action;
    let nextDeselectedFilter;
    let checkSelectedFilter;

    if (Array.isArray(label) && label.length) {
      e_name = "veryme_filter_interaction";
      e_action = null;
      let temp: any = [];
      let nextSelectedFilter: any = [];
      filtersData.forEach(ele => {
        //pull out all selected  filters from filterArray
        if (ele.isSelected) {
          temp.push(ele);
        }
        //filter out selected filter
        checkSelectedFilter = label.find(
          item => item.label === ele.label && item.isSelected !== ele.isSelected
        );
        if (!isObjectEmpty(checkSelectedFilter)) {
          nextSelectedFilter.push(checkSelectedFilter);
        }
      });

      //if no selected filter available then check for deselected filters
      if (!nextSelectedFilter.length) {
        nextDeselectedFilter = temp.filter(
          ({ label: id1 }) => !label.some(({ label: id2 }) => id2 === id1)
        );
      }

      if (nextSelectedFilter && nextSelectedFilter.length) {
        e_label = "selected_" + nextSelectedFilter[0].label;
      } else if (nextDeselectedFilter && nextDeselectedFilter.length) {
        e_label = "deselected_" + nextDeselectedFilter[0].label;
      }
    } else if (Array.isArray(label) && !label.length) {
      let lastDeselectedFilter = filtersData.find(el => el.isSelected);
      e_label = "deselected_" + lastDeselectedFilter.label;
      e_name = "veryme_filter_interaction";
      e_action = null;
    } else {
      e_name = "PageInteraction";
      e_action = "link";
      e_label = label;
    }

    const state = {
      onLoadParams: {
        show_view: false,
        event_name: e_name,
        event_action: e_action,
        event_label: e_label ? e_label : null,
        page_name: "uk>app>veryme rewards>home",
        same_page_event: true
      }
    };
    loadEvents(previousPages, { state });
  };

  const updateProfile = async isMock => {
    const userProfileMaster = UserProfile.getInsatnce("")?.props;
    const {
      id,
      isFirstUse,
      interests,
      currentOnboardingPage,
      totalOnboardingPages,
      ...rest
    } = userProfileDetails;
    const selectedInterests = getInterestsData(interests);
    const profilePayload = {
      ...rest,
      firstUse: false,
      interests: selectedInterests,
      permissions: findEditedPermissions(
        userProfileMaster.permissions,
        rest?.permissions
      )
    };
    if (isMock) {
      console.log(profilePayload);
      return;
    }
    const resp = await updateProfileApiCall(profilePayload);

    if (resp?.data?.updateProfile) {
      const { firstUse, interests, ...rest } = resp?.data?.updateProfile;
      new UserProfile({
        ...userProfileDetails,
        id: "",
        interests: sanitizeInterestData(interests)
      }).$save();
      new UserProfile({ ...userProfileDetails }).$delete("local");
    }
  };

  const handleCloseDialog = () => {
    localStorage.setItem(
      ConstantMapping[clientName].APP_UPDATE_DIALOG_LOCAL_STORAGE_KEY,
      getCurrentTime().toString()
    );
    setOpenAppVersionDialog(false);
  };

  const handleCloseVisitToWinDialog = () => {
    new UserDetails({
      id: "",
      ...userDetails,
      halfVisitToWinPopup: false
    }).$save();
    setOpenVisitToWinOverlayPopup(false);
  };

  const handleCloseVMRLiterDialog = () => {
    localStorage.setItem("VMRLite", getCurrentTime().toString());
    setOpenVMRLiteDialog(false);
  };

  const handleClosePayGplusDialog = () => {
    localStorage.setItem("PAYGPlus", getCurrentTime().toString());
    setOpenpaygPlusDialog(false);
  };

  const handleAnnouncementDialog = () => {
    setAnnouncementDialog(false);
  };

  const convergedRenderOffer = useCallback(
    (offerId, index) => {
      return renderOfferById(offerId, index);
    },
    [favouriteOfferIds, showSavedOfferAnimation]
  );

  const renderOfferById = (offerId: any, index: number) => {
    let offer: any = offers[offerId];
    if (!offer) return null;
    let claimedDetails = getLatestClaimDetailsByClaimId(
      offer.claimedIds,
      claimedOffers
    );
    return (
      <GridOffer
        addRemoveFavorites={(
          offerId: number,
          isStoreGiftingOffer: boolean | null
        ) => {
          if (!isFavoriteCallIP && !isStoreGiftingOffer) {
            isFavoriteCallIP = true;
            addRemoveFavouriteOffers(offerId, favorites, favouriteOfferIds)
              .then((data: any) => {
                data?.add && setSavedOfferAnimation(offerId);
                setTimeout(() => {
                  showSavedOfferAnimation === offerId &&
                    setSavedOfferAnimation(-1);
                }, 800);
                isFavoriteCallIP = false;
                let event_name;
                if (data?.add) {
                  event_name = ConstantMapping[clientName].TEALIUM_OFFER_SAVE;
                } else {
                  event_name = ConstantMapping[clientName].TEALIUM_OFFER_UNSAVE;
                }
                if (updateSaveUnsave_OfferCount() < 3) {
                  manualParameterPass(previousPages, {
                    show_view: false,
                    event_name: event_name,
                    page_name:
                      ConstantMapping[clientName].TEALIUM_MANUAL_ADD_HOME_PAGE,
                    same_page_event: true,
                    veryme_offer_name: offer?.title
                  });
                }
              })
              .catch(() => {
                isFavoriteCallIP = false;
              });
          }
        }}
        claimedDetails={claimedDetails || undefined}
        clientName={clientName}
        merchant={merchants[offer?.merchantId]}
        showSavedAnimation={showSavedOfferAnimation === offerId}
        offer={offer}
      ></GridOffer>
    );
  };

  const logFilterEvent = (
    action: string,
    currentSelectedFilters: IFilter[] | null
  ) => {
    const filterSelected =
      differenceBy(currentSelectedFilters, appliedFilters, "name")?.[0] || {};

    const sanitizeFilterData = pick(filterSelected, ["name", "label"]);

    const eventData = {
      ...sanitizeFilterData,
      eventType: EVENT_TYPE.SET_OFFER_FILTER,
      action: action
    };
    if (!isEmpty(sanitizeFilterData) || action === EVENT_ACTION.CLEAR_ALL) {
      logEvents(eventData);
    }
  };

  const renderNoRewardsEmptyBox = () => {
    return (
      <div className={classes.noOffer}>
        <img
          src={ImagesMapping[clientName].iconEmptyBoxBlack}
          alt="empty-box"
          className={classes.emptyBox}
        />
        <h2 className={classes.noRewardHeading}>
          {ConstantMapping[clientName].NO_REWARDS_HEADING}
        </h2>
        <span className={classes.noRewardsText}>
          {ConstantMapping[clientName].NO_REWARDS_TEXT}
        </span>
      </div>
    );
  };

  const setData = () => {
    let updatedFilters: any = filtersData.map((item: any) => {
      let filter = Object.assign({}, item);
      filter.isSelected = false;
      return filter;
    });
    setFiltersData(updatedFilters);
    updateFilters(updatedFilters);
  };

  const updateFilters = (filtersData: any) => {
    let updatedFilters: any = {};
    filtersData.map(item => {
      let selectedFilter: any = {
        name: item.name,
        label: item.label,
        isSelected: item.isSelected,
        position: item.position,
        selectOrder: item.selectOrder
      };
      updatedFilters[item.name] = selectedFilter;
    });
    new Filters({
      id: "",
      ...updatedFilters
    }).$save();
  };

  const updateFilterData = (selectedFilters: any) => {
    let updatedData: any = [];
    updatedData = (filtersData || []).map((data: any) => {
      let obj: any = { ...data };
      let isObjectPresent = findIndex(selectedFilters, ["name", obj.name]);
      if (isObjectPresent >= 0) {
        obj.isSelected = true;
      } else {
        obj.isSelected = false;
      }
      return obj;
    });
    setFiltersData(updatedData);
    updateFilters(updatedData);
  };

  //Animation styles
  const defaultAnimation = {
    opacity: 0,
    marginTop: -20
  };
  const enterAnimation = {
    opacity: spring(1),
    marginTop: spring(0)
  };

  const shouldOfferRender = (offerId, showIsConvergedOffer) => {
    let offer: any = offers[offerId];
    let isORcodeActive: boolean = offer?.isQRActive;
    const isCustomVendorOffer =
      offer?.addType === cardTypes.eatLocal ||
      offer?.addType === cardTypes.coffeeClub;

    if (!offer || offer?.addType === cardTypes.payGTrial || isORcodeActive)
      return false;

    if (
      isCustomVendorOffer &&
      retireveBannerDetails(offer?.additionalDetails, "isEnabled") === "false"
    ) {
      return false;
    }

    if (showIsConvergedOffer) {
      if (offer.isConvergedOffer) {
        return true;
      } else {
        return false;
      }
    }

    if (offer.isConvergedOffer) {
      return false;
    }

    if (offer.offerType === OfferType.BANNER) {
      return true;
    }
    // Filtering out offers claimed more than 30 days ago

    const longClaimedOffers = Object.values(claimedOffers).filter(
      claimedOffer => {
        return checkOfferClaimedDays(claimedOffer);
      }
    );
    let longClaimedOfferIds = longClaimedOffers?.map(
      claimedOffer => claimedOffer.offerId
    );

    if (longClaimedOfferIds.includes(offerId)) {
      //Reward Offer is Reclaimable so it should render in offer feed untill & unless it has reached it's claiming limit
      if (offer.offerType === OfferType.REWARD) {
        let userClaimLimitsReached = offers[offerId]?.claimLimitDetails?.user;
        let multipleClaimedRewardOffers = Object.values(claimedOffers).filter(
          item => {
            return item.offerId === offerId;
          }
        );
        let latestClaimedRewardsOfferOrder = [];

        //fetch the latest object from  duplicate objects
        if (multipleClaimedRewardOffers?.length > 1) {
          let newArr = multipleClaimedRewardOffers.slice(0);
          latestClaimedRewardsOfferOrder = newArr?.sort((item, item2) => {
            let a = new Date(item.claimDateTime);
            let b = new Date(item2.claimDateTime);
            return b - a;
          });
        } else {
          latestClaimedRewardsOfferOrder = multipleClaimedRewardOffers;
        }

        let latestRewardClaimDaysExceeds =
          latestClaimedRewardsOfferOrder?.length
            ? checkOfferClaimedDays(latestClaimedRewardsOfferOrder[0])
            : false;

        return userClaimLimitsReached && latestRewardClaimDaysExceeds
          ? false
          : true;
      }
      return false;
    }

    return true;
  };

  const checkOfferClaimedDays = claimedOffer => {
    const claimDate = new Date(claimedOffer.claimDateTime);
    const currentDate = new Date();
    const daysDifference = Math.floor(
      (currentDate.getTime() - claimDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    return daysDifference > 30;
  };

  const offersToRender = (showIsConvergedOffer = false) => {
    if (!showIsConvergedOffer) {
      return gridOffers.filter(offerId => {
        return shouldOfferRender(offerId, showIsConvergedOffer);
      });
    } else {
      return convergedOffer.filter(offerId => {
        return shouldOfferRender(offerId, showIsConvergedOffer);
      });
    }
  };

  // Navigate to particular offer sections
  const navigateToOffer = () => {
    handleCloseVisitToWinDialog();
    history.push(`/offer-details/${applicationFlags?.visitToWinId}`);
  };

  const getPopUpImage = (): string => {
    if (cms_v2w_images.v2w_half_overlay_popup_IMAGEURL) {
      return cms_v2w_images.v2w_half_overlay_popup_IMAGEURL;
    }
    if (isChristmas)
      return ImagesMapping[clientName].iwVisit_To_Win_HalfScreen_Overlay;
    return ImagesMapping[clientName].visit_To_Win_HalfScreen_Overlay;
  };

  return (
    <AnimationHelper
      defaultStyles={defaultAnimation}
      enterStyles={enterAnimation}
      units={{ marginTop: "px" }}
      exitStyles={enterAnimation}
      showEnterAnimation={showEnterTransition}
      showExitAnimation={false}
    >
      <Fragment>
        {/* VisitToWin User DialogBox */}
        <GenericDialog
          openDialog={openVisitToWinOverlayPopup}
          content={ConstantMapping[clientName].VISIT_TO_WIN_DIALOG_MESSAGE}
          clientName={clientName}
          heading={ConstantMapping[clientName].VISIT_TO_WIN_DIALOG_HEADING}
          showCloseIcon={true}
          showActionButtons={true}
          popupImage={getPopUpImage()}
          buttonOk="View entries"
          confirmCallback={navigateToOffer}
          buttonOkStyleText={classes.buttonOkStyleText}
          isOkButtonWidthContained={true}
          paperStyle={{ margin: 0, padding: 0 }}
          transitionStyle={{ alignItems: "flex-end" }}
          fullImageDialogBox={true}
          headingStyle={{
            position: "absolute",
            color: "#ffffff",
            bottom: "29%",
            textAlign: "center",
            width: "100%"
          }}
          contentStyle={{
            position: "absolute",
            color: "#ffffff",
            bottom: "25%",
            textAlign: "center",
            width: "100%"
          }}
          buttonContainerStyle={{
            position: "absolute",
            bottom: "3%"
          }}
          buttonOkContainerStyle={{
            backgroundColor: "#ffffff",
            color: "#e60000"
          }}
          closeIconBtnStyle={{ color: "#ffffff", fontSize: "30px" }}
          handleCloseDialog={handleCloseVisitToWinDialog}
          transparentBackground={true}
        />

        {/* VMRLite User DialogBox */}
        <GenericDialog
          openDialog={openVMRLiteDialog}
          content={ConstantMapping[clientName].VMRLite_DIALOG_MESSAGE}
          clientName={clientName}
          heading={ConstantMapping[clientName].VMRLite_DIALOG_HEADING}
          showCloseIcon={false}
          showActionButtons={true}
          popupImage={ImagesMapping[clientName].vmrLite_RedIcon}
          buttonOk="Let's go"
          confirmCallback={handleCloseVMRLiterDialog}
          buttonOkStyleText={classes.buttonOkStyleText}
          isOkButtonWidthContained={true}
        />
        {/* payg+ VMRLite User DialogBox */}
        <GenericDialog
          openDialog={openpaygPlusDialog}
          content={ConstantMapping[clientName].PAYGPLUS_DIALOG_MESSAGE}
          clientName={clientName}
          heading={ConstantMapping[clientName].PAYGPLUS_DIALOG_HEADING}
          showCloseIcon={false}
          showActionButtons={true}
          popupImage={ImagesMapping[clientName].vmrLite_RedIcon}
          buttonOk="Go to rewards"
          confirmCallback={handleClosePayGplusDialog}
          buttonOkStyleText={classes.buttonOkStyleText}
          isOkButtonWidthContained={true}
        />
        {/* Announcement Dialog */}
        <GenericDialog
          openDialog={openAnnoucementDialog}
          content={announcementModuleInfo.description}
          clientName={clientName}
          heading={announcementModuleInfo.title}
          handleCloseDialog={handleAnnouncementDialog}
          showCloseIcon={true}
          showActionButtons={true}
          confirmCallback={() => {
            if (announcementModuleInfo.cta_redirect) {
              if (announcementModuleInfo.cta_redirect === "collection") {
                //update this condition once confirmed from backend team
                history.push(
                  `/collection-card/${+announcementModuleInfo.offerId}`
                );
              } else if (announcementModuleInfo.cta_redirect === "offer") {
                history.push(
                  `/offer-details/${+announcementModuleInfo.offerId}`
                );
              } else {
                setAnnouncementDialog(false);
                history.push("/home");
              }
            } else {
              setAnnouncementDialog(false);
            }
          }}
          isOkButtonWidthContained={true}
          fullImageDialogBox={true}
          transparentBackground={true}
          paperStyle={{
            background: "transparent",
            marginTop: "-62px",
            width: "90%",
            padding: "30px 16px 16px"
          }}
          headingStyle={{
            wordBreak: "break-word",
            color:
              announcementModuleInfo.cta_color === "#ffffff" ? "#fff" : "#000",
            marginTop: "26px",
            width: "100%",
            marginBottom: "16px"
          }}
          contentStyle={{
            color:
              announcementModuleInfo.cta_color === "#ffffff" ? "#fff" : "#000"
          }}
          transitionStyle={{
            backdropFilter: "blur(3px)",
            WebkitBackdropFilter: "blur(3px)",
            backgroundColor: "transparent"
          }}
          closeIconBtnStyle={{
            top: announcementModuleInfo?.top_icon_image ? "72px" : "32px",
            color: "#fff",
            right: "26px",
            fontSize: "32px"
          }}
          buttonContainerStyle={{
            flex: "auto",
            alignItems: "end",
            paddingBottom: "24px"
          }}
          announcementModuleInfo={announcementModuleInfo}
          showAnnouncementPopup={true}
        />
        <div
          className={classes.applySticky}
          style={{
            zIndex: 5,
            top: -2,
            position: "-webkit-sticky"
          }}
        >
          <Header
            headerText=""
            isHeaderInverted={false}
            svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
            clientLogoPrimary={getImageDetails(
              ImagesMapping[clientName].clientLogoPrimary,
              ConstantMapping[clientName].CLIENT_ICON_TEXT
            )}
            clientLogoSecondary={getImageDetails(
              ImagesMapping[clientName].clientLogoSecondary,
              ConstantMapping[clientName].CLIENT_ICON_TEXT
            )}
            onSvgSelection={() => window.open("myvodafone://HOME", "_blank")}
            isScrollHeader={false}
          />
        </div>
        <div>
          {/* carousel view */}
          {carousel?.length ? (
            <div
              style={{
                flex: "1 1 100%",
                overflow: "auto",
                backgroundSize: "100% 93%",
                backgroundRepeat: "no-repeat"
                // padding: "45px 0px 0px"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column"
                  // paddingBottom: "5vw"
                }}
              >
                <CarouselComponent />
              </div>
            </div>
          ) : null}

          {/* filter view */}
          <div className={classes.filter_container}>
            <div className={classes.filter}>
              <Filter
                filters={filtersData}
                label="Filter rewards"
                shouldSortBySelected={false}
                clear={() => {
                  logFilterEvent(EVENT_ACTION.CLEAR_ALL, null);
                  setData();
                  setAppliedFilters([]);
                  setSavedOfferAnimation(-1);
                  setNoGridOffers(false);
                  loadAdobeTealiumEvent("Clear filters");
                }}
                selectedFilters={selectedFilters => {
                  updateFilterData(selectedFilters);
                  logFilterEvent(EVENT_ACTION.TOGGLE_ON, selectedFilters);
                  setAppliedFilters(selectedFilters);
                  setSavedOfferAnimation(-1);
                  loadAdobeTealiumEvent(selectedFilters);
                }}
              ></Filter>
            </div>
            <div
              className={classes.offerFeedWrapper}
              style={{ margin: offerContainerMargin }}
            >
              <ConvergedOffer
                convergedOffer={convergedOffer}
                renderOfferById={convergedRenderOffer}
              />
              <div className={classes.offerContainer}>
                <FlipMove
                  duration={600}
                  enterAnimation="elevator"
                  leaveAnimation="elevator"
                  appearAnimation="elevator"
                  typeName={null}
                >
                  {(offersToRender() || []).map((offerId, index) => {
                    return (
                      <span key={offerId}>
                        {renderOfferById(offerId, index)}
                      </span>
                    );
                  })}
                </FlipMove>
              </div>
            </div>

            {(!gridOffers?.length && appliedFilters?.length) ||
            gridOffersNotAvailable
              ? renderNoRewardsEmptyBox()
              : null}
          </div>
        </div>
      </Fragment>
      <GenericDialog
        openDialog={openAppVersionDialog}
        content={ConstantMapping[clientName].APP_VERSION_DIALOG_MESSAGE}
        clientName={clientName}
        handleCloseDialog={handleCloseDialog}
        buttonCancel={ConstantMapping[clientName].APP_VERSION_DIALOG_CLOSE_TEXT}
        buttonOk=""
        heading={ConstantMapping[clientName].APP_VERSION_DIALOG_HEADING}
        showActionButtons={true}
        showCloseIcon={false}
      />
    </AnimationHelper>
  );
};

const mapStateToProps = (state: any) => {
  const displayCategories = DisplayCategories.getInsatnce("", state)?.props;
  const displayCategoryIds = DisplayCategoryIds.getInsatnce("", state)?.props
    .ids;
  const offers = Offers.getInsatnce("", state)?.props;
  const claimedOffers = ClaimedOffers.getInsatnce("", state)?.props;
  const carousel = Carousel.getInsatnce("", state)?.props?.carousel;
  const merchants = Merchants.getInsatnce("", state)?.props;
  const favorites = FavouriteOffers.getInsatnce("", state)?.props;
  const settings = Settings.getInsatnce("", state).props;
  const offerIdsSortedByWeight =
    OfferIdsSortedByWeight.getInsatnce("", state)?.props?.offerIds || [];
  const userProfileDetails = UserProfile.getInsatnce("local", state)?.props;
  const favouriteOfferIds = SortedFavouriteOfferIds.getInsatnce(
    "",
    state
  )?.props;
  const userDetails = UserDetails.getInsatnce("", state)?.props;
  const filters = Filters.getInsatnce("", state)?.props;
  const applicationFlags = ApplicationFlag.getInsatnce("", state).props;
  const cms_v2w_images = CMSImages.getInsatnce("", state)?.props
    .CMS_visitToWinConfigurations;
  let announcementModuleData = Announcement.getInsatnce("", state)?.props;
  const scrolls = Scrolls.getInsatnce("", state)?.props;

  return {
    displayCategories,
    displayCategoryIds,
    offers,
    carousel,
    merchants,
    favorites,
    userProfileDetails,
    claimedOffers,
    offerIdsSortedByWeight,
    favouriteOfferIds,
    filters,
    userDetails,
    settings,
    applicationFlags,
    cms_v2w_images,
    announcementModuleData,
    scrolls
  };
};
export default connect(mapStateToProps)(Home);
