import { gql } from "apollo-boost";
import {
  FetchAll,
  getFavouriteOffers,
  getClaimedOffers,
  getOfferByDisplayCategoryId
} from "./Queries";
import {
  addFavouriteOffers,
  removeFavouriteOffers,
  updateProfile,
  shareOffer,
  claimOffer,
  saveGamificationDetails,
  saveOfferRatingDetails
} from "./Mutations";

//Queries
export const VodafoneQueries = {
  FETCH_ALL: gql`
    ${FetchAll}
  `,
  GET_FAVORITE_OFFERS: gql`
    ${getFavouriteOffers}
  `,
  GET_CLAIMED_OFFERS: gql`
    ${getClaimedOffers}
  `,
  GET_OFFERS_BY_DISPLAY_CATEGORY_ID: gql`
    ${getOfferByDisplayCategoryId}
  `
};

/**
 * Mutations
 */
export const VodafoneMutations = {
  ADD_FAVORITE: gql`
    ${addFavouriteOffers}
  `,
  REMOVE_FAVORITE: gql`
    ${removeFavouriteOffers}
  `,
  UPDATE_PROFILE: gql`
    ${updateProfile}
  `,
  SHARE_OFFER: gql`
    ${shareOffer}
  `,
  CLAIM_OFFER: gql`
    ${claimOffer}
  `,
  SAVE_GAMIFICATION_MAILS_DETAILS: gql`
    ${saveGamificationDetails}
  `,
  SAVE_OFFER_RATE_DETAILS: gql`
    ${saveOfferRatingDetails}
  `
};
