import React, { useState, useEffect, Fragment } from "react";
import { OfferCard, CardProps } from "gce-cxinteracts-ui-components-library";
import Carousel, { CarouselItem } from "Models/ReduxModels/Carousel";
import { connect } from "react-redux";
import Merchants, { IMerchant } from "Models/ReduxModels/Merchants";
import Offers, {
  ImageClassifier,
  IOffers,
  Offer
} from "Models/ReduxModels/OffersModel";
import { mod } from "react-swipeable-views-core";
import { autoPlay, virtualize } from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";
import {
  isFavoriteOffer,
  getOfferImage,
  addRemoveFavouriteOffers,
  getOverlayIcon,
  getLatestClaimDetailsByClaimId,
  getOfferBadgeDetails,
  isOfferLocked,
  isOfferCodeValid,
  isOfferShared,
  checkIfOfferIsClickable,
  retireveBannerDetails,
  isOfferComingSoon,
  isObjectEmpty,
  gamificatioOffer,
  isOfferCodeExpired
} from "Utils/offerUtils";
import FavouriteOffers, {
  IFavouriteOffers
} from "Models/ReduxModels/FavouriteOffersModel";
import { useHistory } from "react-router-dom";
import {
  checkBannerMode,
  getArticleOfferTextColor,
  getClientName,
  getImageDetails,
  getValueFromHashMapArray,
  manualParameterPass,
  previousPages,
  updateSaveUnsave_OfferCount
} from "Utils/GeneralUtils";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import CollectionCardDetailsModel, {
  CollectionCardItem
} from "Models/ReduxModels/CollectionCardDetailsModel";
import { OfferType } from "Models/Interfaces/OfferType";
import ClaimedOffers, {
  IClaimedOffers
} from "Models/ReduxModels/ClaimedOffersModel";
import OfferActions from "Components/ActionsComponent/ActionsComponent";
import SortedFavouriteOfferIds, {
  OfferIds
} from "Models/ReduxModels/SortedFavouriteOfferIds";
import { useTheme } from "@material-ui/core";
import ApplicationFlag, {
  IApplication
} from "Models/ReduxModels/ApplicationFlag";
import {
  EVENT_TYPE,
  logEvents,
  pushAllEventsToServer
} from "Services/EventService";
import { checkOfferExpiry } from "Routes/RestaurantJourney/Membership/MembershipUtils";

interface IProps {
  carousel: CarouselItem[];
  merchants: IMerchant;
  offers: IOffers;
  favorites: IFavouriteOffers;
  collectionCardDetails: any;
  claimedOffers: IClaimedOffers;
  favouriteOfferIds: OfferIds;
  applicationFlags: IApplication;
}

const AutoPlayVirtualizeSwipeableViews = autoPlay(virtualize(SwipeableViews));

const CarouselComponent: React.FC<IProps> = (props: IProps) => {
  const cardTypes = {
    offer: "offer",
    collectionCard: "collectionCard",
    eatLocal: "Eat Local",
    coffeeClub: "Coffee Club",
    ad: "Ad"
  };

  const history = useHistory();
  const [carouselData, setCarouselData] = useState<CardProps[]>([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [showSavedOfferAnimation, setSavedOfferAnimation] = useState(-1);

  const theme = useTheme();
  const {
    carousel,
    merchants,
    offers,
    favorites,
    claimedOffers,
    collectionCardDetails = {},
    favouriteOfferIds,
    applicationFlags
  } = props;

  const clientName = getClientName();
  let isFavoriteCallIP = false;

  useEffect(() => {
    renderCarouselData();
  }, [favorites]);

  const getCardType = item => {
    if (item.offerId && offers[item.offerId]) {
      return cardTypes.offer;
    } else if (
      item.collectionCardId &&
      collectionCardDetails[item.collectionCardId]
    ) {
      return cardTypes.collectionCard;
    }
  };

  const getCardData = (type, cardData) => {
    const cardDataMap = {
      offer: offerTypesMapping,
      collectionCard: collectionCardMapping
    };

    return cardDataMap[type](cardData);
  };

  const collectionCardMapping = carouselItem => {
    let collectionCard =
      carouselItem.collectionCardId &&
      collectionCardDetails[carouselItem.collectionCardId];

    const subtitle =
      (collectionCard?.showOverlayText && collectionCard?.subTitle) || "";

    return {
      isLocked: false,
      isValidCode: false,
      isFavorite: false,
      isArticle: false,
      offerSubTitle: subtitle,

      imageDetails: getOfferImage(
        ImageClassifier.OFFER_IMAGE_MEDIUM,
        collectionCard?.images || ""
      ),
      logoDetails: "",
      title: (collectionCard?.showOverlayText && collectionCard?.title) || "",
      subTitle: subtitle,
      isBlurred: false,
      orientation: "portrait",
      mode: "poster",
      showFavorites: false, //confirm
      uniqueId: carouselItem?.collectionCardId,
      badge: undefined,
      options: {
        cardActionAltText: {
          saveAltText: ConstantMapping[clientName].SAVE_ALT_TEXT,
          unsaveAltText: ConstantMapping[clientName].UNSAVE_ALT_TEXT
        },
        overlayIcon: undefined,
        overrideComponent: {
          ActionElement: () => null
        }
      }
    };
  };

  const getBadgeDetails = (
    isArticle,
    isBanner,
    offerData,
    claimedOffer: any,
    showEmail = ""
  ) => {
    if (
      isArticle ||
      (checkBannerMode(offerData) && offerData?.addType !== cardTypes.eatLocal)
    ) {
      return;
    } else {
      return getOfferBadgeDetails(
        offerData,
        "medium",
        claimedOffer?.claimedId,
        showEmail
      );
    }
  };

  const getImages = (offer, comingSoonOffer, comingSoonOfferImage) => {
    let images: any;
    if (comingSoonOffer) {
      if (comingSoonOfferImage) {
        images = offer?.comingSoonDetails?.images;
      } else {
        images = offer?.images;
      }
    } else {
      images = offer?.images;
    }
    return images;
  };

  const getTitle = (offer, comingSoonOffer) => {
    let title: string = "";
    if (comingSoonOffer) {
      if (offer?.comingSoonDetails?.title !== (null || "")) {
        title = offer?.comingSoonDetails?.title;
      } else {
        title = offer?.title;
      }
    } else {
      title = offer?.title;
    }
    return title;
  };

  const offerTypesMapping = carouselItem => {
    const offerData = carouselItem.offerId && offers[carouselItem.offerId];
    const isLocked = isOfferLocked(offerData);
    const isValidCode = isOfferCodeValid(offerData);
    const isFavorite = isFavoriteOffer(offerData?.offerId);
    const isArticle = offerData?.offerType === OfferType.ARTICLE;
    const isBanner = offerData?.offerType === OfferType.BANNER;
    const comingSoonOffer = isOfferComingSoon(offerData?.startDateTime);
    const comingSoonOfferImage =
      offerData?.comingSoonDetails?.images &&
      !isObjectEmpty(offerData?.comingSoonDetails?.images);
    let claimedOfferDetails: any;
    if (offerData) {
      claimedOfferDetails = getLatestClaimDetailsByClaimId(
        offerData?.claimedIds,
        claimedOffers
      );
    }
    let gamificationShowEmailPopup = getValueFromHashMapArray(
      claimedOfferDetails?.attributes,
      "gamificationStatus"
    );

    return {
      isLocked: isLocked,
      isValidCode: isValidCode,
      isFavorite: isFavorite,
      isArticle: isArticle,
      isBanner: isBanner,
      offerSubTitle:
        (isArticle && "Read More") || (isBanner && offerData?.titleShort) || "",
      imageDetails: getOfferImage(
        ImageClassifier.OFFER_IMAGE_MEDIUM,
        getImages(offerData, comingSoonOffer, comingSoonOfferImage) || ""
      ),
      logoDetails:
        !isArticle &&
        getImageDetails(merchants[offerData?.merchantId]?.imageUrl || ""),
      title: getTitle(offerData, comingSoonOffer) || "",
      subTitle:
        (isArticle && "Read More") || (isBanner && offerData?.titleShort) || "",
      isBlurred: isLocked && !isValidCode,
      orientation: "portrait",
      mode:
        isArticle || offerData?.showFullAddImage === "true"
          ? "poster"
          : "carousel",
      showFavorites:
        isArticle ||
        (offerData?.claimedIds && offerData?.claimedIds.length) ||
        offerData?.offerType === OfferType.PRIZEDRAW
          ? false
          : true,
      uniqueId: carouselItem?.offerId,
      badge: getBadgeDetails(
        isArticle,
        isBanner,
        offerData,
        claimedOfferDetails,
        gamificationShowEmailPopup
      ),
      options: {
        cardActionAltText: {
          saveAltText: ConstantMapping[clientName].SAVE_ALT_TEXT,
          unsaveAltText: ConstantMapping[clientName].UNSAVE_ALT_TEXT
        },
        overlayIcon:
          isLocked && !isValidCode ? getOverlayIcon(offerData) : undefined,
        overrideComponent: {
          ActionElement: () => {
            return (
              <OfferActions mode="Carousel" offer={offerData}></OfferActions>
            );
          }
        }
      },
      addType: offerData?.addType,
      showFullAddImage: offerData?.showFullAddImage
    };
  };

  const renderCarouselData = () => {
    if (carousel && carousel.length) {
      let carouselOffers: CardProps[] = [];
      let cardData;
      carousel.forEach((item: CarouselItem) => {
        const cardType = getCardType(item);
        cardData = getCardData(cardType, item);
        carouselOffers.push(cardData);
      });

      setCarouselData(carouselOffers);
    }
  };

  const showOfferCardDetails = carouselItem => {
    const onLoadParams = {
      page_name: `${ConstantMapping[clientName].TEALIUM_PAGE_PREFIX}${carouselItem?.title}`,
      page_subsection: "offers"
    };
    const { uniqueId } = carouselItem;
    let isCollectionCard: CollectionCardItem | undefined =
      collectionCardDetails[uniqueId];

    if (isCollectionCard) {
      history.push(`/collection-card/${uniqueId}`, {
        onLoadParams
      });
    } else if (
      carouselItem?.addType === cardTypes.eatLocal ||
      carouselItem?.addType === cardTypes.coffeeClub
    ) {
      history.push(`/custom-vendor/${uniqueId}`, { onLoadParams });
    } else {
      history.push(`/offer-details/${uniqueId}`, { onLoadParams });
    }
  };

  const handleMembershipRedirection = customOffer => {
    const eatLocal = "Eat Local";
    const id = customOffer?.offerId;
    let claimedOfferDetails = getLatestClaimDetailsByClaimId(
      customOffer?.claimedIds,
      claimedOffers
    );
    new ApplicationFlag({
      id: "",
      ...applicationFlags,
      eatLocalCoffeClubID: id,
      eatLocalCoffeClubOffer: customOffer
    }).$save();
    const isMemberActivate = checkOfferExpiry(claimedOffers, {
      offerId: id
    });

    if (customOffer?.addType === eatLocal) {
      logEvents({
        eventType: EVENT_TYPE.EAT_LOCAL_MEMBERSHIP_CLICK,
        offerId: customOffer?.offerId
      });
    } else {
      logEvents({
        eventType: EVENT_TYPE.COFFEE_CLUB_MEMBERSHIP_CLICK,
        offerId: customOffer?.offerId
      });
    }

    const isExpired = isOfferCodeExpired(customOffer);

    let claimedOffer: any;
    if (claimedOfferDetails) {
      let claimedOfferKeys = Object.keys(claimedOfferDetails);
      if (claimedOfferKeys.length > 1) {
        claimedOffer = Object.values(claimedOfferDetails)?.find((obj: any) => {
          return obj == customOffer?.offerId;
        });
      }
    }

    if (!isExpired && claimedOffer && isMemberActivate) {
      if (customOffer?.addType === eatLocal) {
        logEvents({
          eventType: EVENT_TYPE.SHOW_EAT_LOCAL_CARD,
          offerId: customOffer?.offerId
        });
      } else {
        logEvents({
          eventType: EVENT_TYPE.SHOW_LOCAL_BLEND_COFFEE_CLUB_CARD,
          offerId: customOffer?.offerId
        });
      }
      history.push({
        pathname: "/use-card",
        state: {
          claimedOfferDetails: claimedOffer,
          offerAddType: customOffer?.addType
        }
      });
    } else {
      if (customOffer?.addType === eatLocal) {
        logEvents({
          eventType: EVENT_TYPE.SHOW_EAT_LOCAL_MEMBERSHIP_SCREEN,
          offerId: customOffer?.offerId
        });
      } else {
        logEvents({
          eventType: EVENT_TYPE.SHOW_COFFEE_CLUB_MEMBERSHIP_SCREEN,
          offerId: customOffer?.offerId
        });
      }
      history.push({
        pathname: "/membership",
        state: {
          offerId: customOffer?.offerId,
          offerAddType: customOffer?.addType,
          routeFrom: "home"
        }
      });
    }
    pushAllEventsToServer();
  };

  const slideRenderer = (params: any) => {
    const { index, key } = params;
    let isArticle: Boolean;
    let isBanner: Boolean;
    if (!carouselData || !carouselData.length) {
      return <Fragment key={key}></Fragment>;
    }
    const slideIndex = mod(index, carouselData.length);
    const carouselItem = carouselData[slideIndex];
    const offerId: number =
      typeof carouselItem?.uniqueId === "number" ? carouselItem.uniqueId : 0;
    const offer: Offer | undefined = offerId ? offers[offerId] : undefined;
    isArticle = offer?.offerType === OfferType.ARTICLE;
    isBanner = offer?.offerType === OfferType.BANNER;
    let id: any = carouselItem?.uniqueId;
    let cardTextColor: any;
    if (collectionCardDetails[id]) {
      cardTextColor = collectionCardDetails[id]?.textColor;
    } else if (isArticle) {
      cardTextColor = getArticleOfferTextColor(offer);
    } else if (isBanner && offer?.showFullAddImage === "true") {
      cardTextColor =
        offer?.additionalDetails &&
        retireveBannerDetails(offer?.additionalDetails, "textColor");
    } else {
      cardTextColor = theme.palette.secondary.light;
    }
    const isOfferClickable = offer ? checkIfOfferIsClickable(offer) : true;
    let eatLocalLogoStyle =
      offer?.addType === cardTypes.eatLocal ? { maxHeight: "24px" } : {};
    let claimedOfferDetails: any;
    if (offer) {
      claimedOfferDetails = getLatestClaimDetailsByClaimId(
        offer?.claimedIds,
        claimedOffers
      );
    }
    let gamificationShowEmailPopup = getValueFromHashMapArray(
      claimedOfferDetails?.attributes,
      "gamificationStatus"
    );
    return (
      <div key={key}>
        <OfferCard
          mode={carouselItem?.mode}
          imageDetails={carouselItem?.imageDetails}
          logoDetails={carouselItem?.logoDetails}
          showFavorites={carouselItem?.showFavorites}
          title={carouselItem?.title}
          subTitle={carouselItem?.subTitle}
          onClick={() => {
            if (isOfferClickable) {
              showOfferCardDetails(carouselItem);
            }
          }}
          onActionClicked={() => {
            if (offer && offer.claimedIds) {
              let claimedDetails = getLatestClaimDetailsByClaimId(
                offer?.claimedIds,
                claimedOffers
              );
              if (claimedDetails && offer?.offerType === OfferType.BANNER) {
                handleMembershipRedirection(offer);
              } else {
                if (claimedDetails && claimedDetails.claimedId) {
                  if (isOfferShared(offer)) {
                    history.push(`/offer-details/${offerId}`);
                  } else {
                    if (gamificatioOffer(offer, claimedOfferDetails)) {
                      history.push(`/offer-details/${offerId}`);
                    } else {
                      history.push(`/code/${claimedDetails.claimedId}`);
                    }
                  }
                }
              }
            } else {
              if (!isFavoriteCallIP && !offer?.isStoreGiftingOffer) {
                isFavoriteCallIP = true;
                addRemoveFavouriteOffers(offerId, favorites, favouriteOfferIds)
                  .then((data: any) => {
                    data?.add && setSavedOfferAnimation(offerId);
                    setTimeout(() => {
                      showSavedOfferAnimation === offerId &&
                        setSavedOfferAnimation(-1);
                      isFavoriteCallIP = false;
                    }, 800);
                    if (updateSaveUnsave_OfferCount() < 3) {
                      let event_name;
                      if (data?.add) {
                        event_name =
                          ConstantMapping[clientName].TEALIUM_OFFER_SAVE;
                      } else {
                        event_name =
                          ConstantMapping[clientName].TEALIUM_OFFER_UNSAVE;
                      }
                      manualParameterPass(previousPages, {
                        show_view: false,
                        event_name: event_name,
                        page_name:
                          ConstantMapping[clientName]
                            .TEALIUM_MANUAL_ADD_HOME_PAGE,
                        same_page_event: true,
                        veryme_offer_name: offer?.title
                      });
                    }
                  })
                  .catch(() => {
                    isFavoriteCallIP = false;
                  });
              }
            }
          }}
          isFavorite={carouselItem?.isFavorite}
          showSavedAnimation={showSavedOfferAnimation === offerId}
          style={{
            margin: "5vw 4px 7vw 5px",
            height: "calc(45vw + 150px)",
            color: cardTextColor
          }}
          uniqueId={carouselItem.uniqueId + "carousel"}
          options={{
            cardActionAltText: {
              saveAltText: ConstantMapping[clientName].SAVE_ALT_TEXT,
              unsaveAltText: ConstantMapping[clientName].UNSAVE_ALT_TEXT
            },
            overlayIcon: carouselItem?.options?.overlayIcon,
            overrideComponent:
              typeof offer !== "undefined"
                ? {
                    ActionElement: () => {
                      return (
                        <OfferActions
                          mode="Carousel"
                          offer={offer}
                          claimedOffer={claimedOfferDetails}
                        ></OfferActions>
                      );
                    }
                  }
                : undefined,
            overrideStyle: { Logo: eatLocalLogoStyle }
          }}
          isBlurred={carouselItem?.isBlurred}
          badge={carouselItem?.badge}
          animationProps={{
            showEnterAnimation: activeStep === index,
            showExitAnimation: activeStep !== index
          }}
          showCarouselAnimation={true}
          showAnimation={true}
          tabIndex="-1"
        ></OfferCard>
      </div>
    );
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <AutoPlayVirtualizeSwipeableViews
      style={{ padding: "0px 8.8vw 0px 9.171vw" }}
      index={activeStep}
      onChangeIndex={handleStepChange}
      enableMouseEvents
      slideRenderer={slideRenderer}
    ></AutoPlayVirtualizeSwipeableViews>
  );
};

const mapStateToProps = (state: any) => {
  const carousel = Carousel.getInsatnce("", state).props?.carousel;
  const merchants = Merchants.getInsatnce("", state).props;
  const offers = Offers.getInsatnce("", state).props;
  const favorites = FavouriteOffers.getInsatnce("", state)?.props;
  let collectionCardDetails = CollectionCardDetailsModel.getInsatnce(
    "",
    state
  )?.props;
  const claimedOffers = ClaimedOffers.getInsatnce("", state)?.props;
  const favouriteOfferIds = SortedFavouriteOfferIds.getInsatnce(
    "",
    state
  )?.props;
  const applicationFlags = ApplicationFlag.getInsatnce("", state).props;
  const props: IProps = {
    carousel,
    offers,
    merchants,
    favorites,
    collectionCardDetails,
    claimedOffers,
    favouriteOfferIds,
    applicationFlags
  };
  return props;
};
export default connect(mapStateToProps)(CarouselComponent);
