import React, { useState, useEffect, Fragment } from "react";
import {
  Dialog,
  Typography,
  Button,
  TextField,
  Icon,
  colors
} from "@material-ui/core";
import { isArray } from "lodash";
import { ThemeMapping } from "Configurations/ThemeMapping";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { ImagesMapping } from "Configurations/ImagesMapping";
import InputAdornment from "@material-ui/core/InputAdornment";
import DoneIcon from "@material-ui/icons/Done";
import { email } from "decoders";
import { set } from "date-fns/fp";
import { Header } from "gce-cxinteracts-ui-components-library";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start"
    },
    dialogHeaderIcon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%"
    },
    dialogActionButtonFirst: {
      margin: theme.spacing(0, 1, 0, 0),
      height: theme.spacing(10),
      textTransform: "none",
      textAlign: "center",
      flex: 1
    },
    dialogActionButtonLast: {
      margin: theme.spacing(0, 0, 0, 1),
      height: theme.spacing(10),
      textTransform: "none",
      textAlign: "center",
      flex: 1
    },
    dialogActionButtonTextWidth: {
      margin: theme.spacing(0, 0, 0, 1),
      minHeight: theme.spacing(10),
      textTransform: "none",
      textAlign: "center",
      width: "max-content",
      padding: "12px 42px"
    },
    dialogActionButtonContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      margin: theme.spacing(7.5, 0, 0, 0)
    },
    dialogHeading: {
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: 1,
      margin: theme.spacing(0, 0, 4, 0),
      fontFamily: "VodafoneRgBold"
    },
    dialogBody: {
      fontSize: theme.typography.h5.fontSize,
      lineHeight: "21px",
      margin: theme.spacing(0, 0, 1, 0),
      color: "rgb(68,68,68)"
    },
    announcementContent: {
      lineHeight: "21px",
      margin: theme.spacing(0, 0, 1, 0),
      marginRight: "0px",
      fontSize: "20px",
      wordBreak: "break-word",
      "& p": {
        margin: "0px"
      }
    },
    dialogHalfImage: {
      margin: theme.spacing(0, "auto"),
      marginBottom: theme.spacing(6),
      width: "52.5%"
    },
    dialogFullImage: {
      width: "100%",
      margin: 0
    },
    dialogCrossIcon: {
      position: "absolute",
      top: "9px",
      right: "11px",
      width: "16px",
      fontSize: "21px",
      paddingTop: "2px",
      paddingBottom: "2px",
      color: "rgba(0, 0, 0, 0.26)"
    },
    trasparentBg: {
      background: "transparent"
    },
    emailPopContainer: {
      display: "flex",
      flexDirection: "column",
      alignSelf: "center"
    },
    subTitle: {
      textAlign: "center",
      color: theme.palette.primary.main,
      fontSize: "14px",
      lineHeight: "17px"
    },
    backButtonStyles: {
      position: "absolute",
      top: 9,
      right: "11px",
      width: "16px",
      fontSize: "21px",
      paddingTop: "2px",
      paddingBottom: "2px",
      left: 9,
      color: theme.palette.common.white
    },
    iwHeader: {
      zIndex: 6,
      top: 0,
      position: "absolute",
      width: "100vw"
    },
    emailDialogContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      backgroundColor: theme.palette.common.white,
      position: "absolute",
      top: "17%",
      left: "5%",
      right: "5%",
      padding: "25px",
      borderRadius: "12px"
    },
    emailDialogSubmitButton: {
      backgroundColor: "rgb(230, 0, 0)",
      display: "flex",
      marginTop: "10px",
      alignSelf: "center",
      minWidth: "149px",
      fontSize: "18px",
      height: "48px",
      fontFamily: "VodafoneRgBold",
      fontWeight: 700,
      color: "#FFFFFF",
      textTransform: "none"
    },
    transparentBackground: {
      backgroundColor: "transparent !important"
    },
    blurBackground: {
      filter: "blur"
    },
    backImage: {
      width: "100%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      aspectRatio: "1",
      borderBottomLeftRadius: "12px",
      borderBottomRightRadius: "12px"
    },
    announcementButton: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      margin: theme.spacing(5, 0, 0, 0),
      minHeight: "50px",
      padding: "0px 18px",
      borderRadius: "12px"
    },
    announcementButtonText: {
      fontFamily: "VodafoneRgBold",
      fontWeight: "bolder",
      fontSize: "18px"
    },
    announcementCTA: {
      height: theme.spacing(10),
      textTransform: "none",
      textAlign: "center",
      padding: "12px 20px",
      borderRadius: "12px",
      "@media (min-width:760px)": {
        height: "52px !important"
      }
    },
    announcementCenterIcon: {
      textAlign: "center",
      zIndex: 1,
      height: "36px"
    },
    announcementContentContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: "0px 14px 0px 14px"
    },
    announcementPaper: {
      "@media (max-width:323px)": {
        marginTop: "0px !important",
        maxHeight: "none",
        marginRight: "12px",
        marginLeft: "12px"
      },
      "@media (min-width:760px)": {
        margin: "98px !important",
        maxHeight: "none"
      }
    },
    announcementIcon: {
      height: "60px",
      "@media (min-width:760px)": {
        height: "72px !important"
      }
    }
  })
);

interface IProps {
  openDialog: boolean;
  clientName: string;
  handleCloseDialog?: () => void;
  confirmCallback?: (data?) => void;
  buttonOk?: string | null;
  buttonCancel?: string | null;
  content: any | undefined;
  heading: string | undefined | null;
  popupImage?: string;
  showCloseIcon?: boolean;
  buttonOkStyleText?: any;
  isOkButtonWidthContained?: boolean;
  fullImageDialogBox?: boolean;
  paperStyle?: any;
  transitionStyle?: any;
  headingStyle?: any;
  contentStyle?: any;
  buttonContainerStyle?: any;
  showActionButtons?: boolean;
  buttonCancelContainerStyle?: any;
  buttonOkContainerStyle?: any;
  closeIconBtnStyle?: any;
  transparentBackground?: boolean;
  showFullScreenDialog?: boolean;
  showEmailPopUp?: boolean;
  isHeaderTransparent?: boolean;
  announcementModuleInfo?: any;
  showAnnouncementPopup?: boolean;
}

const GenericDialog: React.FC<IProps> = ({
  openDialog,
  content,
  clientName,
  handleCloseDialog,
  confirmCallback,
  buttonOk,
  buttonCancel,
  heading,
  popupImage,
  showCloseIcon,
  buttonOkStyleText,
  isOkButtonWidthContained = false,
  fullImageDialogBox = false,
  paperStyle = {},
  transitionStyle = {},
  headingStyle = {},
  contentStyle = {},
  buttonContainerStyle = {},
  showActionButtons = false,
  buttonCancelContainerStyle = {},
  buttonOkContainerStyle = {},
  closeIconBtnStyle = {},
  transparentBackground = false,
  showEmailPopUp = false,
  showFullScreenDialog = false,
  isHeaderTransparent,
  announcementModuleInfo,
  showAnnouncementPopup = false
}) => {
  const classes = useStyles();
  //@ts-ignore
  const theme = ThemeMapping[clientName];

  const renderHeader = () => {
    return (
      <Typography
        className={classes.dialogHeading}
        style={{ marginTop: showCloseIcon ? "8px" : "0", ...headingStyle }}
      >
        {heading}
      </Typography>
    );
  };

  const renderClaimAdvice = () => {
    let advice = isArray(content) ? content : [content];
    return (
      <>
        {advice.map(item => (
          <Typography
            key={item}
            className={classes.dialogBody}
            style={{
              marginRight: showCloseIcon ? "8px" : "0",
              ...contentStyle
            }}
            dangerouslySetInnerHTML={{
              __html: item
            }}
          />
        ))}
      </>
    );
  };

  const renderActionButtons = () => {
    return (
      <div
        className={classes.dialogActionButtonContainer}
        style={{ ...buttonContainerStyle }}
      >
        {buttonCancel && (
          <Button
            variant="contained"
            color="primary"
            className={classes.dialogActionButtonFirst}
            onClick={handleCloseDialog}
            style={{ ...buttonCancelContainerStyle }}
          >
            <Typography>{buttonCancel}</Typography>
          </Button>
        )}
        {buttonOk && (
          <Button
            variant="contained"
            color="primary"
            className={
              isOkButtonWidthContained
                ? classes.dialogActionButtonTextWidth
                : classes.dialogActionButtonLast
            }
            onClick={confirmCallback}
            style={{ ...buttonOkContainerStyle }}
          >
            <Typography noWrap className={buttonOkStyleText}>
              {buttonOk}
            </Typography>
          </Button>
        )}
      </div>
    );
  };

  const renderImage = () => (
    <>
      <img
        src={popupImage}
        alt="dialogImage"
        className={
          fullImageDialogBox ? classes.dialogFullImage : classes.dialogHalfImage
        }
      />
    </>
  );

  const renderCloseIcon = () => (
    <div onClick={handleCloseDialog}>
      <IconButton
        aria-label="close"
        // color="inherit"
        className={classes.dialogCrossIcon}
        onClick={handleCloseDialog}
        style={{
          ...closeIconBtnStyle
        }}
      >
        <CloseIcon color={"inherit"} fontSize={"inherit"} />
      </IconButton>
    </div>
  );

  const renderBackButton = () => {
    let svgProps = Object.assign({ ...closeIconBtnStyle });
    svgProps.fill = theme.palette.text.secondary;
    return (
      <div className={classes.iwHeader}>
        <Header
          className={isHeaderTransparent ? classes.transparentBackground : ""}
          svgProps={svgProps}
          onSvgSelection={handleCloseDialog}
        />
      </div>
    );
  };

  const renderContent = () => {
    if (showEmailPopUp) return <EmailContainer />;
    if (showAnnouncementPopup) return <AnnouncementContainer />;

    return (
      <>
        {showCloseIcon && renderCloseIcon()}
        <div className={classes.dialogContainer}>
          {popupImage && renderImage()}
          {renderHeader()}
          {renderClaimAdvice()}
          {showActionButtons && renderActionButtons()}
        </div>
      </>
    );
  };

  const validateEmail = email => {
    const emailRegExp =
      /^[^ -._!^*+/?|\":,~<>=[\]{}`\\()';@#%&$]+\S+@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})+$/;
    if (email !== "" || email !== null) {
      return emailRegExp.test(email);
    }
    return false;
  };

  const EmailContainer = () => {
    const [email, setEmail] = useState("");
    const [cEmail, setCEmail] = useState("");
    const [fName, setFName] = useState("");
    const [sName, setSName] = useState("");
    const [isValidFirstEmail, setIsValidFirstEmail] = useState(false);
    const [isValidSecondEmail, setIsValidSecondEmail] = useState(false);
    const [isSecondEmailFocused, setIsSecondEmailFocused] = useState(false);
    const [isFirstEmailFocused, setIsFirstEmailFocused] = useState(false);

    useEffect(() => {
      if (validateEmail(email)) {
        setIsValidFirstEmail(true);
      } else {
        setIsValidFirstEmail(false);
      }
    }, [email]);

    useEffect(() => {
      if (validateEmail(email) && email === cEmail) {
        setIsValidSecondEmail(true);
      } else {
        setIsValidSecondEmail(false);
      }
    }, [cEmail, email]);

    return (
      <div>
        {showCloseIcon && renderBackButton()}

        <div className={classes.emailDialogContainer}>
          <Typography
            className={classes.dialogHeading}
            style={{
              marginTop: showCloseIcon ? "8px" : "0",
              textAlign: "center",
              fontSize: "30px",
              ...headingStyle
            }}
          >
            {heading}
          </Typography>
          <div className={classes.emailPopContainer}>
            <Typography variant="subtitle2" className={classes.subTitle}>
              Please provide your email address within 24 hours. We will only
              use your email to contact you about your prize
            </Typography>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <TextField
                placeholder="First name"
                id="fName"
                onChange={e => setFName(e.target.value)}
                value={fName}
                style={{
                  marginRight: "10px",
                  marginBottom: "10px",
                  marginTop: "10px",
                  fontSize: "12px"
                }}
                variant="outlined"
              />
              <TextField
                placeholder="Last name"
                value={sName}
                onChange={e => setSName(e.target.value)}
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontSize: "12px"
                }}
                variant="outlined"
              />
            </div>
            <TextField
              fullWidth
              placeholder="Your email address"
              variant="outlined"
              onFocusCapture={c => {
                setIsFirstEmailFocused(true);
              }}
              onBlur={b => {
                // setValues({ ...values, isFirstEmailFocused: false });
              }}
              value={email}
              onChange={e => setEmail(e.target.value)}
              style={{ marginRight: "10px", fontSize: "12px" }}
              InputProps={{
                endAdornment: isFirstEmailFocused ? (
                  isValidFirstEmail ? (
                    <InputAdornment position="end">
                      <DoneIcon style={{ fill: "green" }} />
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <CloseIcon color="primary" />
                    </InputAdornment>
                  )
                ) : null
              }}
            />
            <TextField
              fullWidth
              placeholder="Confirm your email address"
              value={cEmail}
              onFocusCapture={c => {
                setIsSecondEmailFocused(true);
              }}
              onBlur={b => {
                // setValues({ ...values, isSecondEmailFocued: false });
              }}
              variant="outlined"
              onChange={e => setCEmail(e.target.value)}
              style={{
                marginRight: "10px",
                marginTop: "10px",
                marginBottom: "10px",
                fontSize: "12px"
              }}
              InputProps={{
                endAdornment: isSecondEmailFocused ? (
                  isValidSecondEmail ? (
                    <InputAdornment position="end">
                      <DoneIcon style={{ fill: "green" }} />
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <CloseIcon color="primary" />
                    </InputAdornment>
                  )
                ) : null
              }}
            />
            <Typography
              variant="subtitle2"
              style={{ textAlign: "center", fontSize: "9px" }}
            >
              The personal data you provide will be used to contact you about
              the prize you’ve won and will be processed in accordance with
              Vodafone Limited's privacy policy. When necessary, your personal
              data will be shared with our Partner and processed in accordance
              with Partner’s Privacy Policy as displayed in the offer T&Cs. By
              clicking Submit, you confirm that you have read and understood our
              privacy policy.{" "}
            </Typography>

            <Button
              disabled={!isValidSecondEmail}
              onClick={() => {
                confirmCallback && confirmCallback({ fName, sName, email });
              }}
              className={classes.emailDialogSubmitButton}
              style={{
                ...(!isValidSecondEmail
                  ? {
                      color: "rgba(0, 0, 0, 0.26)",
                      backgroundColor: "rgb(216 210 210)"
                    }
                  : {})
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const AnnouncementContainer = () => {
    return (
      <Fragment>
        {announcementModuleInfo?.top_icon_image && (
          <div className={classes.announcementCenterIcon}>
            <img
              src={announcementModuleInfo?.top_icon_image}
              className={classes.announcementIcon}
              alt="centerImage"
            />
          </div>
        )}
        {showCloseIcon && renderCloseIcon()}
        <div className={classes.dialogContainer}>
          {
            <img
              src={announcementModuleInfo?.banner_image}
              alt="dialogImage"
              className={
                fullImageDialogBox
                  ? classes.dialogFullImage
                  : classes.dialogHalfImage
              }
            />
          }
          <div
            className={classes.backImage}
            style={{
              backgroundImage: `url('${announcementModuleInfo?.background_image}')`
            }}
          >
            <div className={classes.announcementContentContainer}>
              {renderHeader()}
              <Typography
                className={classes.announcementContent}
                style={{
                  ...contentStyle
                }}
                dangerouslySetInnerHTML={{
                  __html: content
                }}
              />
              {
                <div
                  className={classes.announcementButton}
                  style={{ ...buttonContainerStyle }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.announcementCTA}
                    onClick={confirmCallback}
                    style={{
                      color:
                        announcementModuleInfo.cta_color === "#e60000"
                          ? "#fff"
                          : "#000",
                      background: announcementModuleInfo.cta_color
                    }}
                  >
                    <Typography
                      noWrap
                      className={classes.announcementButtonText}
                    >
                      {announcementModuleInfo?.cta_text}
                    </Typography>
                  </Button>
                </div>
              }
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      fullScreen={showFullScreenDialog}
      PaperProps={{
        classes: {
          root: showAnnouncementPopup ? classes.announcementPaper : ""
        },
        style: {
          backgroundColor: theme.palette.secondary.main,
          width: "100%",
          padding: "30px 20px 20px 20px",
          color: theme.palette.secondary.contrastText,
          position: "relative",
          ...paperStyle
        }
      }}
      TransitionProps={{
        style: {
          background: "rgba(0, 0, 0, 0.4)",
          opacity: 1,
          transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          backgroundColor: "transparent",
          ...transitionStyle
        }
      }}
      disableBackdropClick={true}
      BackdropProps={{
        classes: { root: transparentBackground ? classes.trasparentBg : "" }
      }}
    >
      {renderContent()}
    </Dialog>
  );
};

export default GenericDialog;
