import { ConstantMapping } from "Configurations/ConstantsMapping";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { getClientName, getImageDetails } from "Utils/GeneralUtils";
import { AnimationHelper, Header } from "gce-cxinteracts-ui-components-library";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { restDetailsUseStyles } from "./styles";
import { useStyles } from "../styles";
import { Box, Button, Typography, useTheme } from "@material-ui/core";
import { connect } from "react-redux";
import ClaimedOffers from "Models/ReduxModels/ClaimedOffersModel";
import {
  availabilityIcon,
  maxDinersDefault,
  maxDiners1,
  maxDiners2,
  maxDiners3,
  maxDiners4,
  maxDiners5,
  maxDiners6,
  maxDiners7,
  maxDiners8,
  maxDiners9,
  maxDiners10,
  noAvailabilityIcon,
  bankHolidayIcon
} from "Constants/Require/Require";
import ApplicationFlag from "Models/ReduxModels/ApplicationFlag";
import defaultImg from "Configurations/Clients/vodafone/Assets/Membership/membershipstartImg.png";
import phone from "Configurations/Clients/vodafone/Assets/RestoLocalBlendImages/icon-phone-grey.png";

import {
  EVENT_TYPE,
  logEvents,
  pushAllEventsToServer
} from "Services/EventService";
import { isOfferCodeExpired } from "Utils/offerUtils";
import { checkOfferExpiry } from "../Membership/MembershipUtils";
import { showSnackBar } from "Utils/SnackBarUtils";
import {
  titleDefaultStyles,
  titleEnterStyles,
  titleLeaveStyles
} from "../RestaurantUtil";
import { claimOfferGraphQLCall } from "Services/OfferSerivice";
import PreClaimDialog from "Routes/OfferDetails/PreClaimDialog";
import { PreClaimDialogTypes } from "Models/Interfaces/PreClaimDialogTypes";

interface IProps {
  claimedOfferDetails?: any;
  applicationFlags: any;
}
const eatLocal = "Eat Local";

const RestaurantDetails: React.FC<IProps> = (props: IProps) => {
  const { claimedOfferDetails, applicationFlags } = props;
  const history = useHistory();
  const location = useLocation();
  const clientName = getClientName();
  let classes = useStyles();
  let restDetailsClasses = restDetailsUseStyles();
  const theme = useTheme();
  const restaurantProps = location.state?.restaurantProps;
  const {
    restaurantImage,
    restaurantName,
    cuisines,
    infoLabelOffer,
    id,
    offerDetail
  } = restaurantProps;
  const [showEnterAnime, setShowEnterAnime] = useState(false);
  const [showLeaveAnime, setShowLeaveAnime] = useState(false);
  const [showLoader, setLoaderState] = useState(false);
  const maxDinersIcons = {
    1: maxDiners1,
    2: maxDiners2,
    3: maxDiners3,
    4: maxDiners4,
    5: maxDiners5,
    6: maxDiners6,
    7: maxDiners7,
    8: maxDiners8,
    9: maxDiners9,
    10: maxDiners10,
    default: maxDinersDefault
  };
  const [maxDinersIcon, setMaxDinersIcon] = useState(maxDinersDefault);

  const singleUseQR = offerDetail?.offers[0]?.claimMethods[0]?.type == "ECODE";

  const parentEatLocalOfferId =
    applicationFlags?.eatLocalCoffeClubOffer?.offerId;

  const isExpired = isOfferCodeExpired(
    applicationFlags?.eatLocalCoffeClubOffer
  );
  const isMemberActivate = checkOfferExpiry(claimedOfferDetails, {
    offerId: parentEatLocalOfferId
  });

  let claimedOffer: any;
  if (claimedOfferDetails) {
    claimedOffer = Object.values(claimedOfferDetails)?.find(
      (obj: any) => obj.offerId == parentEatLocalOfferId
    );
  }

  useEffect(() => {
    setShowEnterAnime(true);
  }, []);

  const goBack = () => {
    setShowLeaveAnime(true);
    setShowEnterAnime(false);
    setTimeout(() => {
      var oldUrl = window.location.href;
      window.history.back();
      setTimeout(() => {
        if (window.location.href === oldUrl) {
          history.push("/home");
        }
      }, 10);
    });
  };

  const redirectToHome = () => {
    setTimeout(() => {
      new ApplicationFlag({
        id: "",
        ...applicationFlags,
        isRedirectedFromGiftingSuccessPage: false,
        isShowFooter: true,
        eatLocalCoffeClubID: 0,
        eatLocalCoffeClubOffer: {},
        selectedShopOrResto: {}
      }).$save();
    }, 250);

    setTimeout(() => history && history.push("/home"), 150);
  };

  const getAddress = address => {
    let text = "";
    if (offerDetail?.address?.[address] !== "") {
      if (address === "postcode") {
        text = offerDetail?.address?.[address];
      } else {
        text = `${offerDetail?.address?.[address]}, `;
      }
    }
    return text;
  };

  let fetchAddress = () => {
    let formattedAddress =
      getAddress("building") +
      getAddress("street") +
      getAddress("town") +
      getAddress("county") +
      getAddress("country") +
      getAddress("postcode");

    if (formattedAddress.endsWith(", ")) {
      formattedAddress = formattedAddress.slice(0, -2);
    }
    return formattedAddress;
  };

  const renderCard = () => {
    return (
      <div style={{ position: "relative" }} id="cardContainer">
        <div className={restDetailsClasses.cardImage}>
          <img src={restaurantImage ? restaurantImage : defaultImg} />
          {/* <div className={restDetailsClasses.innerDiv}>
            <p className={classes.imageCountRestoDetailsPage}>
              {restaurantImage.length}
              <span className={classes.infoLabelDays}></span>
            </p>
          </div> */}
        </div>
      </div>
    );
  };

  const getAvailableDays = (): string[] => {
    const daysOfWeek = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday"
    ];
    const availableDays: string[] = [];
    let startDay: string | null = null;
    let prevDay: string | null = null;

    for (const day of daysOfWeek) {
      const apiDay = Object.keys(infoLabelOffer[0].availability).find(
        d => d.toLowerCase() === day.toLowerCase()
      );
      if (apiDay && infoLabelOffer[0].availability[apiDay]?.day) {
        if (prevDay === null) {
          // startDay = day;
          startDay = day.charAt(0).toUpperCase() + day.slice(1).toLowerCase(); // Capitalize first letter, lowercase rest
        }
        // prevDay = day;
        prevDay = day.charAt(0).toUpperCase() + day.slice(1).toLowerCase(); // Capitalize first letter, lowercase rest
      } else {
        if (prevDay !== null) {
          if (prevDay === startDay) {
            availableDays.push(startDay);
          } else {
            availableDays.push(`${startDay} - ${prevDay}`);
          }
          startDay = null;
          prevDay = null;
        }
      }
    }

    // Check if there's a remaining range after iterating through all days
    if (prevDay !== null) {
      if (prevDay === startDay) {
        availableDays.push(startDay!);
      } else {
        availableDays.push(`${startDay!} - ${prevDay}`);
      }
    }

    return availableDays;
  };

  const copyToClipboard = text => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showSnackBar("Booking number copied to clipboard");
      })
      .catch(error => {
        showSnackBar("Error copying booking number to clipboard:", error);
      });
  };

  // Function to format available days into string
  const formatAvailability = (availableDays: string[]): string => {
    if (availableDays?.length === 0) {
      return "Not available";
    }
    return `${availableDays.join(", ")}`;
  };

  const availableDays = getAvailableDays();
  const availabilityString = formatAvailability(availableDays);
  const colonIndex = offerDetail?.notice?.indexOf(":");
  const boldText = offerDetail?.notice?.slice(0, colonIndex + 1);
  const normalText = offerDetail?.notice?.slice(colonIndex + 1).trim();

  const extractErrorMessage = (error: any): string => {
    let errorMessage;
    errorMessage = error.message || "An error occurred.";
    const start = errorMessage.indexOf('"error":"');
    if (start !== -1) {
      const end = errorMessage.indexOf('"', start + '"error":"'.length);
      if (end !== -1) {
        return errorMessage.substring(start + '"error":"'.length, end);
      }
    } else {
      errorMessage = "Something went wrong, please try again";
    }
    return errorMessage;
  };

  const callEcodeRedeemGQL = async () => {
    let offer = applicationFlags?.eatLocalCoffeClubOffer;
    const membershipId = claimedOffer?.redemptionCode || "";
    const ratePlanId =
      offerDetail?.offers?.[0]?.claimMethods?.[0]?.ecodeBatchId || "";
    const restId = offerDetail?.id || "";
    setLoaderState(true);

    try {
      let res = await claimOfferGraphQLCall(
        applicationFlags?.eatLocalCoffeClubID,
        membershipId,
        ratePlanId,
        restId
      );
      let ecodeClaimedOffer = res?.data?.claimOffer;

      if (ecodeClaimedOffer) {
        if (offer?.addType === eatLocal) {
          logEvents({
            eventType: EVENT_TYPE.CONFIRM_ECODE_EAT_LOCAL_SUCCESS,
            offerId: offer?.offerId
          });
        } else {
          logEvents({
            eventType: EVENT_TYPE.CONFIRM_ACTIVATE_COFFEE_CLUB_SUCCESS,
            offerId: offer?.offerId
          });
        }
      }
      pushAllEventsToServer();
      setLoaderState(false);
      history.push({
        pathname: "/show-qr-code",
        state: {
          claimedOfferDetails: ecodeClaimedOffer,
          offerDetail: offerDetail
        }
      });
    } catch (error: any) {
      setLoaderState(false);
      if (error?.networkError) {
        history.push("/error-500");
      } else {
        console.error("GraphQL error:", error.message);
        const extractedError = extractErrorMessage(error);
        showSnackBar(extractedError);
      }
      if (offer?.addType === eatLocal) {
        logEvents({
          eventType: EVENT_TYPE.CONFIRM_ECODE_EAT_LOCAL_ERROR,
          offerId: offer?.offerId
        });
      } else {
        logEvents({
          eventType: EVENT_TYPE.CONFIRM_ACTIVATE_COFFEE_CLUB_ERROR,
          offerId: offer?.offerId
        });
      }
      pushAllEventsToServer();
    }
  };

  useEffect(() => {
    let maxDinersNumber = infoLabelOffer[0]?.maxDiners || "default";
    setMaxDinersIcon(maxDinersIcons[maxDinersNumber] || maxDinersDefault);
  }, [infoLabelOffer]);

  let formattedDescription = offerDetail?.description.replace(/\n/g, "<br />");

  return (
    <>
      <AnimationHelper
        defaultStyles={titleDefaultStyles}
        enterStyles={titleEnterStyles}
        exitStyles={titleLeaveStyles}
        showEnterAnimation={showEnterAnime}
        showExitAnimation={showLeaveAnime}
        enterAnimationDelay={650}
        exitAnimationDelay={100}
        units={{
          marginTop: "px"
        }}
      >
        <Header
          headerText=""
          isHeaderInverted={false}
          svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
          clientLogoPrimary={getImageDetails(
            ImagesMapping[clientName].clientLogoPrimary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          clientLogoSecondary={getImageDetails(
            ImagesMapping[clientName].clientLogoSecondary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          onSvgSelection={goBack}
          onLogoSelection={redirectToHome}
          isScrollHeader={false}
        />
        <div className={restDetailsClasses.wrapper}>
          {renderCard()}
          <div style={{ padding: "10px 15px 100px 15px" }}>
            <div className={classes.cardContent}>
              <div>
                <p className={classes.infoLabelRestoDetailsPage}>
                  {infoLabelOffer[0]?.name}
                  <span className={classes.infoLabelDays}></span>
                </p>
              </div>
              <h2 className={classes.cardName}>{restaurantName}</h2>
              <h4 className={classes.addressText}>{fetchAddress()}</h4>
              <h4 className={restDetailsClasses.cuisineText}>
                {cuisines[0]?.name}
              </h4>
            </div>
            {offerDetail?.notice && (
              <Box className={restDetailsClasses.restoDetailsNoteBox}>
                <div className={restDetailsClasses.strap} />
                <div>
                  <b>{boldText}</b>
                  <h4 className={restDetailsClasses.noticeText}>
                    {normalText.trim()}
                  </h4>
                </div>
              </Box>
            )}
            <div className={restDetailsClasses.cardBottomContent}>
              <h2 className={restDetailsClasses.impStuffHeader}>
                The important stuff
              </h2>
              {infoLabelOffer[0]?.maxDiners > 0 && (
                <div style={{ display: "flex" }}>
                  <img
                    src={maxDinersIcon}
                    alt="max-diners-icon"
                    className={restDetailsClasses.svgIcons}
                  />
                  <h3 className={restDetailsClasses.impStuffText}>
                    <b>Max diners: </b> {infoLabelOffer[0]?.maxDiners}{" "}
                  </h3>
                </div>
              )}
              {infoLabelOffer[0]?.availability && (
                <div style={{ display: "flex" }}>
                  <img
                    src={availabilityIcon}
                    alt="availability-icon"
                    className={restDetailsClasses.svgIcons}
                  />
                  <h3 className={restDetailsClasses.impStuffText}>
                    <b>Available on: </b>
                    {availabilityString}{" "}
                  </h3>
                </div>
              )}
              {infoLabelOffer[0]?.genericExclusions?.excludeBankHoliday && (
                <div style={{ display: "flex" }}>
                  <img
                    src={bankHolidayIcon}
                    alt="non-availability-icon"
                    className={restDetailsClasses.svgIcons}
                  />
                  <h3 className={restDetailsClasses.impStuffText}>
                    Excludes Bank Holidays
                  </h3>
                </div>
              )}
              {infoLabelOffer[0]?.genericExclusions?.excludeCelebration && (
                <div style={{ display: "flex" }}>
                  <img
                    src={noAvailabilityIcon}
                    alt="non-availability-icon"
                    className={restDetailsClasses.svgIcons}
                  />
                  <h3 className={restDetailsClasses.impStuffText}>
                    Excludes Celebrations
                  </h3>
                </div>
              )}
              {offerDetail?.description && (
                <>
                  <h2 className={restDetailsClasses.impStuffHeader}>
                    Venue details
                  </h2>
                  <Typography
                    className={restDetailsClasses.impStuffText}
                    style={{ margin: "0px" }}
                    dangerouslySetInnerHTML={{
                      __html: formattedDescription
                    }}
                  />
                </>
              )}

              {offerDetail?.bookingTelephone && (
                <>
                  <h2 className={restDetailsClasses.impStuffHeader}>
                    Contact details for booking
                  </h2>
                  <div style={{ display: "flex" }}>
                    <img
                      src={phone}
                      alt="call"
                      className={restDetailsClasses.svgIcons}
                    />
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        copyToClipboard(offerDetail?.bookingTelephone)
                      }
                    >
                      <h3 className={restDetailsClasses.impStuffText}>
                        {offerDetail?.bookingTelephone}
                      </h3>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div style={{ position: "fixed", bottom: 0, width: "100%" }}>
            <hr />
            <div className={restDetailsClasses.footer}>
              {claimedOffer && isMemberActivate && !isExpired ? (
                <Button
                  variant="contained"
                  className={restDetailsClasses.redButton}
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    boxShadow: theme.shadows[4],
                    margin: "0 auto",
                    width: "100%"
                  }}
                  onClick={() => {
                    let offer = applicationFlags?.eatLocalCoffeClubOffer;
                    if (!singleUseQR) {
                      if (offer?.addType === eatLocal) {
                        logEvents({
                          eventType: EVENT_TYPE.EAT_LOCAL_USE_CARD,
                          offerId: offer?.offerId,
                          ...{
                            redemtiontype: singleUseQR ? "e-CODE" : "membership"
                          }
                        });
                        logEvents({
                          eventType: EVENT_TYPE.SHOW_EAT_LOCAL_CARD,
                          offerId: offer?.offerId
                        });
                      } else {
                        logEvents({
                          eventType: EVENT_TYPE.COFFEE_CLUB_USE_CARD,
                          offerId: offer?.offerId,
                          ...{
                            redemtiontype: singleUseQR ? "e-CODE" : "membership"
                          }
                        });
                        logEvents({
                          eventType:
                            EVENT_TYPE.SHOW_LOCAL_BLEND_COFFEE_CLUB_CARD,
                          offerId: offer?.offerId
                        });
                      }
                      pushAllEventsToServer();
                    }

                    singleUseQR
                      ? callEcodeRedeemGQL()
                      : history.push({
                          pathname: "/use-card",
                          state: { claimedOfferDetails: claimedOffer }
                        });
                  }}
                >
                  Use card
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className={restDetailsClasses.redButton}
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    boxShadow: theme.shadows[4],
                    margin: "0 auto",
                    width: "100%"
                  }}
                  onClick={() => {
                    let offer = applicationFlags?.eatLocalCoffeClubOffer;

                    if (offer?.addType === eatLocal) {
                      logEvents({
                        eventType: EVENT_TYPE.ACTIVATE_EAT_LOCAL,
                        offerId: applicationFlags?.eatLocalCoffeClubID,
                        ...{
                          restaurantId: id,
                          restaurantName: restaurantName,
                          redemtiontype: singleUseQR ? "e-CODE" : "membership"
                        }
                      });
                    } else {
                      logEvents({
                        eventType: EVENT_TYPE.ACTIVATE_COFFEE_CLUB,
                        offerId: applicationFlags?.eatLocalCoffeClubID,
                        ...{
                          restaurantId: id,
                          restaurantName: restaurantName,
                          redemtiontype: singleUseQR ? "e-CODE" : "membership"
                        }
                      });
                    }
                    pushAllEventsToServer();
                    history.push({
                      pathname: "/membership",
                      state: {
                        id: id,
                        offerDetail: offerDetail,
                        claimedOfferDetails: claimedOffer,
                        path: "restaurant-details",
                        restaurantProps: restaurantProps
                      }
                    });
                  }}
                >
                  {!isMemberActivate && isExpired
                    ? "Renew membership"
                    : "Activate membership"}
                </Button>
              )}
            </div>
          </div>
        </div>
        {showLoader && (
          <PreClaimDialog
            openDialog={showLoader}
            type={PreClaimDialogTypes.LOADER}
            clientName={clientName}
            confirmCallback={() => {}}
            handleCloseDialog={() => {}}
            claimAdvice={""}
          />
        )}
      </AnimationHelper>
    </>
  );
};

const mapStateToProps = (state: any) => {
  let claimedOfferDetails = ClaimedOffers.getInsatnce("", state)?.props;
  let applicationFlags = ApplicationFlag.getInsatnce("", state).props;
  return {
    claimedOfferDetails,
    applicationFlags
  };
};

export default connect(mapStateToProps)(RestaurantDetails);
