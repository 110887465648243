import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { Header } from "gce-cxinteracts-ui-components-library";
import { membershipConstants } from "../constants";
import { getClientName, getImageDetails } from "Utils/GeneralUtils";
import { claimOfferGraphQLCall } from "Services/OfferSerivice";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import ApplicationFlag from "Models/ReduxModels/ApplicationFlag";
import eatLocalWelcome from "Configurations/Clients/vodafone/Assets/RestoLocalBlendImages/eatLocalMembership.png";
import localBlendWelcome from "Configurations/Clients/vodafone/Assets/RestoLocalBlendImages/coffeeclubmembership.png";
import RestoLocalBFilterModal from "Models/ReduxModels/RestoLocalBlendFilters";
import FilteredRestaurants from "Models/ReduxModels/FilteredRestaurants";
import ccLogo from "Configurations/Clients/vodafone/Assets/RestoLocalBlendImages/cclogo.png";
import {
  EVENT_TYPE,
  logEvents,
  pushAllEventsToServer
} from "Services/EventService";
import SortedClaimedIds, {
  ClaimIds
} from "Models/ReduxModels/SortedClaimedIds";
import ClaimedOffers, {
  IClaimedOffers
} from "Models/ReduxModels/ClaimedOffersModel";
import Offers, { IOffers } from "Models/ReduxModels/OffersModel";
import {
  getLatestClaimDetailsByClaimId,
  isOfferCodeExpired,
  updateClaimedOffersFromApi
} from "Utils/offerUtils";
import { showSnackBar } from "Utils/SnackBarUtils";
import RestaurantsList, {
  IList
} from "Models/ReduxModels/RestaurantCoffeeModel";
import { checkOfferExpiry } from "./MembershipUtils";
import PreClaimDialog from "Routes/OfferDetails/PreClaimDialog";
import { PreClaimDialogTypes } from "Models/Interfaces/PreClaimDialogTypes";
import { membShipUseStyles } from "./styles";

interface IProps {
  applicationFlags: any;
  subHeading: string;
  location: any;
  claimedOffers: IClaimedOffers;
  sortedClaimedIds: ClaimIds;
  offers: IOffers;
  restaurantList: IList;
}

const eatLocal = "Eat Local";

const Membership: React.FC<IProps> = (props: IProps) => {
  const {
    applicationFlags,
    claimedOffers,
    sortedClaimedIds,
    offers,
    restaurantList
  } = props;
  const stateId = props?.location?.state?.offerId;
  const routeFrom = props?.location?.state?.routeFrom;
  const type = applicationFlags?.eatLocalCoffeClubOffer?.addType;
  let subHeading =
    type === eatLocal
      ? membershipConstants.SUBHEADING
      : membershipConstants.LOCALBLEND_SUBHEADING;
  let subTitle =
    type === eatLocal
      ? membershipConstants.SUBTITLE
      : membershipConstants.LOCALBLEND_SUBTITLE;
  const history = useHistory();
  let clientName = getClientName();
  const memberShipClasses = membShipUseStyles();
  const [showLoader, setLoaderState] = useState(false);
  const id = stateId ? stateId : applicationFlags.eatLocalCoffeClubID;
  let offer = applicationFlags?.eatLocalCoffeClubOffer;

  const goBack = () => {
    if (offer?.addType === eatLocal) {
      logEvents({
        eventType: EVENT_TYPE.CANCEL_EAT_LOCAL_ACTIVATION,
        offerId: offer?.offerId
      });
    } else {
      logEvents({
        eventType: EVENT_TYPE.CANCEL_COFFEE_CLUB_ACTIVATION,
        offerId: offer?.offerId
      });
    }
    pushAllEventsToServer();
    setTimeout(() => window.history.back(), 500);
  };

  const redirectToHome = () => {
    setTimeout(() => {
      history.push("/home");
      new RestoLocalBFilterModal({
        id: "",
        offerType: [],
        cuisine: "",
        availability: ""
      }).$save();
      let list = {};
      new FilteredRestaurants({
        id: "",
        ...list
      }).$save();
      new ApplicationFlag({
        id: "",
        ...applicationFlags,
        isShowFooter: true,
        isRedirectedFromGiftingSuccessPage: false,
        eatLocalCoffeClubID: 0,
        eatLocalCoffeClubOffer: {},
        selectedShopOrResto: {}
      }).$save();
    }, 500);
  };

  const reFetchConditions = (updatedOffers, claimedId, newClaimedOffers) => {
    let offerClaimed =
      getLatestClaimDetailsByClaimId(claimedId, newClaimedOffers) || null;
    try {
      updateClaimedOffersFromApi(
        updatedOffers,
        offer,
        newClaimedOffers,
        offerClaimed
      );
    } catch (error: any) {
      if (error?.networkError) {
        history.push("/error-500");
      } else {
        showSnackBar();
      }
    }
  };

  const isMemberActivate = checkOfferExpiry(claimedOffers, { id });

  const isExpired = isOfferCodeExpired(offer);

  const activateMembership = async () => {
    setLoaderState(true);
    try {
      let res = await claimOfferGraphQLCall(id);
      let claimedOffer = res?.data?.claimOffer;
      let sortedclaimedIds: number[] = sortedClaimedIds?.claimIds;

      if (claimedOffer) {
        if (offer?.addType === eatLocal) {
          logEvents({
            eventType: EVENT_TYPE.CONFIRM_ACTIVATE_EAT_LOCAL_SUCCESS,
            offerId: offer?.offerId
          });
        } else {
          logEvents({
            eventType: EVENT_TYPE.CONFIRM_ACTIVATE_COFFEE_CLUB_SUCCESS,
            offerId: offer?.offerId
          });
        }
        //save updated response in redux here
        let reclaimObject = {};
        Object.keys(claimedOffers).map(val => {
          if (claimedOffers[val]?.offerId !== +id) {
            reclaimObject[val] = claimedOffers[val];
          }
        });
        let currentClaimedOffer: any = {
          claimedId: claimedOffer?.id,
          offerId: claimedOffer?.offerId,
          attributes: claimedOffer?.attributes,
          byAgent: claimedOffer?.byAgent,
          claimDateTime: new Date(claimedOffer?.claimDateTime),
          expiryDateTime: claimedOffer?.expiryDateTime
            ? new Date(claimedOffer?.expiryDateTime)
            : undefined,
          offerType: claimedOffer?.offerType,
          redemptionCode: claimedOffer?.redemptionCode
        };
        if (offers[id].claimedIds && offers[id].claimedIds.length > 0) {
          let idx = sortedclaimedIds.indexOf(offers[id].claimedIds[0]);
          sortedclaimedIds.splice(idx, 1);
          // setReclaimMembership(true);
        }
        sortedclaimedIds.splice(0, 0, claimedOffer.id);
        let newClaimedOffers = reclaimObject || {};
        newClaimedOffers[currentClaimedOffer.claimedId] = currentClaimedOffer;
        let updatedOffers = offers;
        updatedOffers[id].claimedIds = [claimedOffer.id];

        new Offers({
          id: "",
          ...updatedOffers
        }).$save();
        new ClaimedOffers({
          id: "",
          ...newClaimedOffers
        }).$save();
        new SortedClaimedIds({
          id: "",
          claimIds: sortedclaimedIds
        }).$save();

        setTimeout(() => {
          reFetchConditions(
            updatedOffers,
            updatedOffers[id].claimedIds,
            newClaimedOffers
          );
        }, 150);
      }
      pushAllEventsToServer();
      setLoaderState(false);

      history.push({
        pathname: "/welcome-membership",
        state: {
          claimedOfferDetails: claimedOffer,
          offerId: id,
          offerDetail: props?.location?.state?.offerDetail,
          path: props?.location?.state?.path,
          restaurantProps: props?.location?.state?.restaurantProps,
          mainRouteFrom: routeFrom
        }
      });
    } catch (error: any) {
      console.log(error);
      setLoaderState(false);
      if (error?.networkError) {
        history.push("/error-500");
      } else {
        showSnackBar(error?.message);
      }
      if (offer?.addType === eatLocal) {
        logEvents({
          eventType: EVENT_TYPE.CONFIRM_ACTIVATE_EAT_LOCAL_ERROR,
          offerId: offer?.offerId
        });
      } else {
        logEvents({
          eventType: EVENT_TYPE.CONFIRM_ACTIVATE_COFFEE_CLUB_ERROR,
          offerId: offer?.offerId
        });
      }
      pushAllEventsToServer();
    }
  };

  const getImage = () => {
    let img;
    if (type === eatLocal) {
      img = eatLocalWelcome;
    } else {
      img = localBlendWelcome;
    }
    return img;
  };

  return (
    <div className={memberShipClasses.mainContainer}>
      <Header
        headerText=""
        isHeaderInverted={true}
        svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
        clientLogoPrimary={getImageDetails(
          ImagesMapping[clientName].clientLogoPrimary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        clientLogoSecondary={getImageDetails(
          ImagesMapping[clientName].clientLogoSecondary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        onSvgSelection={goBack}
        onLogoSelection={redirectToHome}
        isScrollHeader={false}
      />
      <div
        className={memberShipClasses.imageContainer}
        style={{ backgroundImage: `url(${getImage()})` }}
      >
        {type !== eatLocal ? <img style={{ width: "42%" }} src={ccLogo} /> : ""}
      </div>
      <div className={memberShipClasses.content}>
        <Typography
          className={memberShipClasses.subHeading}
          dangerouslySetInnerHTML={{
            __html: subHeading
          }}
        />
        <Typography
          className={memberShipClasses.subTitle}
          dangerouslySetInnerHTML={{
            __html: subTitle
          }}
        />
        <div />
        <div className={memberShipClasses.buttonContainer}>
          <Button
            variant="contained"
            // color="primary"
            className={memberShipClasses.buttonOk}
            onClick={e => activateMembership()}
            disableRipple
          >
            <Typography>
              {!isMemberActivate && isExpired
                ? "Renew membership"
                : "Activate membership"}
            </Typography>
          </Button>
          <div
            className={memberShipClasses.cancelPermissionText}
            onClick={e => goBack()}
          >
            <Typography>{"Back"}</Typography>
          </div>
        </div>
      </div>
      {showLoader && (
        <PreClaimDialog
          openDialog={showLoader}
          type={PreClaimDialogTypes.LOADER}
          clientName={clientName}
          confirmCallback={() => {}}
          handleCloseDialog={() => {}}
          claimAdvice={""}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  let applicationFlags = ApplicationFlag.getInsatnce("", state).props;
  let claimedOffers = ClaimedOffers.getInsatnce("", state)?.props;
  const sortedClaimedIds = SortedClaimedIds.getInsatnce("", state)?.props;
  const offers = Offers.getInsatnce("", state)?.props;
  let restaurantList = RestaurantsList.getInsatnce("", state)?.props;

  return {
    applicationFlags,
    claimedOffers,
    sortedClaimedIds,
    offers,
    restaurantList
  };
};

export default connect(mapStateToProps)(Membership);
