import React, { Fragment, useEffect, useState } from "react";
import { Offer, ImageClassifier } from "Models/ReduxModels/OffersModel";
import { OfferType } from "Models/Interfaces/OfferType";
import ClaimedOffers, {
  IClaimedOffers,
  ClaimedOffer
} from "Models/ReduxModels/ClaimedOffersModel";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import OfferActions from "Components/ActionsComponent/ActionsComponent";
import { OfferCard, IBadgeProps } from "gce-cxinteracts-ui-components-library";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  isFavoriteOffer,
  getOfferImage,
  getOverlayIcon,
  getOfferBadgeDetails,
  isOfferLocked,
  isOfferCodeValid,
  isOfferShared,
  checkIfOfferIsClickable,
  isOfferComingSoon,
  isObjectEmpty,
  retireveBannerDetails,
  checkConvergedClaim,
  getLatestClaimDetailsByClaimId,
  gamificatioOffer,
  isOfferCodeExpired
} from "Utils/offerUtils";
import {
  checkBannerMode,
  getArticleOfferTextColor,
  getImageDetails,
  getValueFromHashMapArray
} from "Utils/GeneralUtils";
import { Merchant } from "Models/ReduxModels/Merchants";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { useTheme } from "@material-ui/core";
import ApplicationFlag from "Models/ReduxModels/ApplicationFlag";
import { checkOfferExpiry } from "Routes/RestaurantJourney/Membership/MembershipUtils";
import {
  EVENT_TYPE,
  logEvents,
  pushAllEventsToServer
} from "Services/EventService";
interface IProps {
  offer: Offer;
  claimedDetails?: ClaimedOffer;
  merchant: Merchant;
  clientName: string;
  addRemoveFavorites: (
    offerId: number,
    isStoreGiftingOffer: boolean | null
  ) => void;
  style?: CSSProperties;
  onOfferClick?: () => void;
  showSavedAnimation?: boolean;
  claimedOfferDetails?: IClaimedOffers;
  applicationFlags?: any;
}
const GridOffer: React.FC<IProps> = (props: IProps) => {
  const {
    offer,
    claimedDetails,
    merchant,
    clientName,
    addRemoveFavorites,
    style,
    onOfferClick,
    showSavedAnimation,
    claimedOfferDetails,
    applicationFlags
  } = props;
  const cardTypes = {
    eatLocal: "Eat Local",
    coffeeClub: "Coffee Club"
  };

  const history = useHistory();
  const theme = useTheme();
  const [isArticle, setIsArticle] = useState(false);
  const [isClaimedOffer, setIsClaimedOffer] = useState(false);
  const [offerBadge, setOfferBadge] = useState<IBadgeProps | undefined>();

  const isShared = isOfferShared(offer);
  const isLocked = isOfferLocked(offer);
  const isValidCode = isOfferCodeValid(offer);
  const isOfferClickable = checkIfOfferIsClickable(offer);
  const comingSoonOffer = isOfferComingSoon(offer?.startDateTime);
  const comingSoonOfferImages =
    offer?.comingSoonDetails?.images &&
    !isObjectEmpty(offer?.comingSoonDetails?.images);
  let onLoadParams = {
    page_name: `${ConstantMapping[clientName].TEALIUM_PAGE_PREFIX}${offer?.title}`,
    page_subsection: "offers"
  };
  const isBanner = offer?.offerType === OfferType.BANNER;
  let claimedOffer;
  if (claimedOfferDetails) {
    claimedOffer = getLatestClaimDetailsByClaimId(
      offer?.claimedIds,
      claimedOfferDetails
    );
  }

  useEffect(() => {
    setIsArticle(offer?.offerType === OfferType.ARTICLE);
    setIsClaimedOffer(
      offer.claimedIds && offer.claimedIds.length ? true : false
    );
    let gamificationShowEmailPopup = getValueFromHashMapArray(
      claimedOffer?.attributes,
      "gamificationStatus"
    );
    setOfferBadge(
      getOfferBadgeDetails(
        offer,
        "small",
        claimedOffer?.claimedIds,
        gamificationShowEmailPopup
      )
    );
  }, [offer]);

  const getImages = () => {
    let images: any;
    if (comingSoonOffer) {
      if (comingSoonOfferImages) {
        images = offer?.comingSoonDetails?.images;
      } else {
        images = offer?.images;
      }
    } else {
      images = offer?.images;
    }
    return images;
  };

  const getTitle = () => {
    let title: any = "";
    let defaultTitle: string = offer?.titleShort
      ? offer?.titleShort
      : offer?.title;
    if (comingSoonOffer) {
      if (offer?.comingSoonDetails?.shortTitle !== (null || "")) {
        title = offer?.comingSoonDetails?.shortTitle;
      } else {
        title = defaultTitle;
      }
    } else {
      title = defaultTitle;
    }
    return title;
  };

  const gridOfferStyle = () => {
    let style: any = { boxShadow: theme.shadows[2] };
    if (isArticle) {
      style = { ...style, color: getArticleOfferTextColor(offer) };
    } else if (isBanner && offer?.showFullAddImage === "true") {
      style = {
        ...style,
        color: retireveBannerDetails(offer?.additionalDetails, "textColor")
      };
    } else {
      style = { ...style, color: theme.palette.secondary.light };
    }

    return { ...style };
  };

  let eatLocalLogoStyle =
    offer?.addType === cardTypes.eatLocal ||
    offer?.addType === cardTypes.coffeeClub
      ? { maxHeight: "19px" }
      : {};

  let convergedClaimedStyle = {
    Image: {
      backgroundImage:
        "linear-gradient(to right, rgba(255,21,4,0.65), rgba(255,21,7,0.75)), url('" +
        getOfferImage(ImageClassifier.OFFER_IMAGE_SMALL, getImages()).url +
        "')"
    }
  };

  const checkOverrideStyle = () => {
    let style = {};
    if (checkConvergedClaim(offer)) {
      style = { ...convergedClaimedStyle };
    }
    return { ...style, Logo: eatLocalLogoStyle };
  };

  const handleMembershipRedirection = customOffer => {
    const eatLocal = "Eat Local";
    const id = customOffer?.offerId;
    const isMemberActivate = checkOfferExpiry(claimedOfferDetails, {
      offerId: id
    });
    new ApplicationFlag({
      id: "",
      ...applicationFlags,
      eatLocalCoffeClubID: id,
      eatLocalCoffeClubOffer: customOffer
    }).$save();

    if (customOffer?.addType === eatLocal) {
      logEvents({
        eventType: EVENT_TYPE.EAT_LOCAL_MEMBERSHIP_CLICK,
        offerId: customOffer?.offerId
      });
    } else {
      logEvents({
        eventType: EVENT_TYPE.COFFEE_CLUB_MEMBERSHIP_CLICK,
        offerId: customOffer?.offerId
      });
    }

    const isExpired = isOfferCodeExpired(customOffer);

    let claimedOffer: any;
    if (claimedOfferDetails) {
      let claimedOfferKeys = Object.keys(claimedOfferDetails);
      if (claimedOfferKeys.length > 1) {
        claimedOffer = Object.values(claimedOfferDetails)?.find(
          (obj: any) => obj?.offerId == customOffer?.offerId
        );
      }
    }

    if (!isExpired && claimedOffer && isMemberActivate) {
      if (customOffer?.addType === eatLocal) {
        logEvents({
          eventType: EVENT_TYPE.SHOW_EAT_LOCAL_CARD,
          offerId: customOffer?.offerId
        });
      } else {
        logEvents({
          eventType: EVENT_TYPE.SHOW_LOCAL_BLEND_COFFEE_CLUB_CARD,
          offerId: customOffer?.offerId
        });
      }
      history.push({
        pathname: "/use-card",
        state: {
          claimedOfferDetails: claimedOffer,
          offerAddType: customOffer?.addType
        }
      });
    } else {
      if (customOffer?.addType === eatLocal) {
        logEvents({
          eventType: EVENT_TYPE.SHOW_EAT_LOCAL_MEMBERSHIP_SCREEN,
          offerId: customOffer?.offerId
        });
      } else {
        logEvents({
          eventType: EVENT_TYPE.SHOW_COFFEE_CLUB_MEMBERSHIP_SCREEN,
          offerId: customOffer?.offerId
        });
      }

      history.push({
        pathname: "/membership",
        state: {
          offerId: customOffer?.offerId,
          offerAddType: customOffer?.addType,
          routeFrom: "home"
        }
      });
    }
    pushAllEventsToServer();
  };

  const isCustomVendorOffer =
    offer?.addType === cardTypes.eatLocal ||
    offer?.addType === cardTypes.coffeeClub;
  return (
    <Fragment>
      <div
        key={offer.offerId}
        style={
          style
            ? style
            : {
                margin: `${theme.spacing(3)}px ${theme.spacing(
                  2.25
                )}px ${theme.spacing(2.25)}px 0px`
              }
        }
      >
        <OfferCard
          mode={isArticle || checkBannerMode(offer) ? "posterGrid" : "grid"}
          imageDetails={getOfferImage(
            ImageClassifier.OFFER_IMAGE_SMALL,
            getImages()
          )}
          logoDetails={getImageDetails(isArticle ? "" : merchant.imageUrl, "")}
          showFavorites={
            checkConvergedClaim(offer)
              ? false
              : isArticle ||
                isClaimedOffer ||
                offer.offerType === OfferType.PRIZEDRAW
              ? false
              : true
          }
          title={getTitle()}
          onClick={() => {
            if (isOfferClickable) {
              onOfferClick && onOfferClick();
              if (isCustomVendorOffer) {
                history.push(`/custom-vendor/${offer.offerId}`, {
                  onLoadParams
                });
              } else if (offer.title === "error_page_test") {
                history.push("/error-500");
              } else {
                history.push(`/offer-details/${offer.offerId}`, {
                  onLoadParams
                });
              }
            }
          }}
          subTitle={isArticle ? "Read more" : ""}
          uniqueId={offer.offerId}
          isFavorite={isFavoriteOffer(offer.offerId)}
          showSavedAnimation={showSavedAnimation}
          onActionClicked={() => {
            if (offer.claimedIds && offer.claimedIds.length) {
              if (claimedDetails && claimedDetails.claimedId) {
                if (isShared) {
                  history.push(`/offer-details/${offer.offerId}`, {
                    onLoadParams
                  });
                } else if (isCustomVendorOffer) {
                  handleMembershipRedirection(offer);
                } else {
                  if (gamificatioOffer(offer, claimedOffer)) {
                    history.push(`/offer-details/${offer.offerId}`, {
                      onLoadParams
                    });
                  } else {
                    history.push(`/code/${claimedDetails.claimedId}`, {
                      onLoadParams
                    });
                  }
                }
              }
            } else {
              addRemoveFavorites(offer.offerId, offer.isStoreGiftingOffer);
            }
          }}
          badge={
            checkConvergedClaim(offer)
              ? offerBadge
              : isArticle || checkBannerMode(offer)
              ? undefined
              : offerBadge
          }
          style={gridOfferStyle()}
          options={{
            cardActionAltText: {
              saveAltText: ConstantMapping[clientName].SAVE_ALT_TEXT,
              unsaveAltText: ConstantMapping[clientName].UNSAVE_ALT_TEXT
            },
            overlayIcon: checkConvergedClaim(offer)
              ? checkConvergedClaim(offer)
              : isLocked && !isValidCode
              ? getOverlayIcon(offer)
              : undefined,
            overrideComponent: {
              ActionElement: () => {
                return (
                  <OfferActions
                    mode="Grid"
                    offer={offer}
                    claimedOffer={claimedOffer}
                  ></OfferActions>
                );
              }
            },
            overrideStyle: checkOverrideStyle()
          }}
          isBlurred={
            checkConvergedClaim(offer) ? true : isLocked && !isValidCode
          }
        ></OfferCard>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  let claimedOfferDetails = ClaimedOffers.getInsatnce("", state)?.props;
  let applicationFlags = ApplicationFlag.getInsatnce("", state).props;

  return {
    claimedOfferDetails,
    applicationFlags
  };
};
export default connect(mapStateToProps)(GridOffer);
